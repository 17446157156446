import * as Api from '@ViewModels';
import { StyleDeclarationValue } from 'aphrodite';
import { createContext, useContext, useEffect, useState } from 'react';
import { AppState } from '../../models/AppState';
import { ICallSuggestionHintEvent } from '../../viewModels/phonecall';
import { IWebsocketSubscription } from '../../viewModels/websockets';

export const TwilioContext = createContext(null);

export interface IKeywordSuggestion {
	title: string;
	message: string;
	score: number;
}

export interface ISalesAiSentiment {
	score: number;
	color: StyleDeclarationValue;
	keyword: string;
}

export interface ISalesAiSentimentEvent {
	id: string;
	score: number;
	color: StyleDeclarationValue;
	keyword: string;
}

export const TwilioContextProvider: React.FC = ({ children }) => {
	const { websockets } = AppState;
	const [suggestions, setSuggestions] = useState<IKeywordSuggestion[]>([]);
	const [completeTopicIdentifiers, setCompleteTopicIdentifiers] = useState<string[]>([]);
	const [incompleteTopicIdentifiers, setIncompleteTopicIdentifiers] = useState<string[]>([]);

	const suggestedResponseHintHandler = async (events: Api.IRemoteEvent<ICallSuggestionHintEvent>[]) => {
		const newSuggestions: IKeywordSuggestion[] = [];
		events.forEach(event => {
			const result = event.value;
			const newSuggestion: IKeywordSuggestion = {
				title: result.label,
				message: result.suggestedResponse,
				score: result.score,
			};
			newSuggestions.push(newSuggestion);
		});
		setSuggestions(prevSuggestions => {
			return [...prevSuggestions, ...newSuggestions].filter(
				(suggA, index, array) => array.findIndex(suggB => suggB.title === suggA.title) === index
			);
		});
	};

	const scorecardHandler = (events: Api.IRemoteEvent<Api.IScorecardEvent>[]) => {
		const lastEvent = events[events.length - 1].value;

		setCompleteTopicIdentifiers(lastEvent.completeTopicIdentifiers);
		setIncompleteTopicIdentifiers(lastEvent.incompleteTopicIdentifiers);
	};

	useEffect(() => {
		const callSuggestionHint: IWebsocketSubscription = {
			callback: suggestedResponseHintHandler,
			events: ['CallSuggestionHintEvent'],
			name: 'callSuggestionHintEvent',
		};

		const scorecardSubscription: IWebsocketSubscription = {
			callback: scorecardHandler,
			events: ['ScorecardEvent'],
			name: 'scorecardEvent',
		};

		websockets.subscribe(callSuggestionHint);
		websockets.subscribe(scorecardSubscription);

		return () => {
			websockets.unsubscribe('callSuggestionHintEvent');
			websockets.unsubscribe('scorecardEvent');
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const context = {
		suggestions,
		setSuggestions,
		completeTopicIdentifiers,
		setCompleteTopicIdentifiers,
		incompleteTopicIdentifiers,
		setIncompleteTopicIdentifiers,
	};
	return <TwilioContext.Provider value={context}>{children}</TwilioContext.Provider>;
};

export const useTwilio = () => {
	const context = useContext(TwilioContext);

	if (!context) {
		throw new Error('Cannot use Twilio without context');
	}

	return context;
};
