import { IBaseApiModel, INoteEvent, IPhoneNumberMetadata, IUser, IScorecard } from '..';

export interface IPhoneCallCategory {
	archived?: boolean;
	hexColor?: string;
	id?: string;
	name: string;
	usage?: number;
}

export enum PhoneCallDirection {
	Outbound = 'Outbound',
	Inbound = 'Inbound',
}

export interface IStoredFile {
	accountId?: string;
	composedFilePath?: string;
	fileName?: string;
	publicUrl?: string;
	regionName?: string;
	resourceType?: string;
}

export enum PhoneCallRecordingTranscriptionStatus {
	AvailableForTranscription = 'AvailableForTranscription',
	RecordingValidationFailure = 'RecordingValidationFailure',
	TranscriptionComplete = 'TranscriptionComplete',
	TranscriptionFailure = 'TranscriptionFailure',
}

export interface IPhoneCall extends IBaseApiModel {
	callStatus?: string;
	categories?: IPhoneCallCategory[];
	companyId?: string;
	contactIds?: string[];
	creationDate?: string;
	creator?: { id: string };
	creatorPhoneNumber?: IPhoneNumberMetadata;
	direction?: PhoneCallDirection;
	duration?: number;
	endTime?: string;
	phoneNumber?: IPhoneNumberMetadata;
	recording?: IStoredFile;
	recordingChannels?: number;
	recordingTranscriptionStatus?: PhoneCallRecordingTranscriptionStatus;
	startTime?: string;
	transcriptionId?: string;
	activeScorecard?: IScorecard;
	activeScorecardId?: string;
	availableScorecardIds?: string[];
}

export enum TranscriptionStatusFilter {
	TranscriptionComplete = 'TranscriptionComplete',
	TranscriptionAvailable = 'TranscriptionAvailable',
}

export enum DealStageOptions {
	AppointmentNoShow = 'Appointment No Show',
	AppointmentRescheduled = 'Appointment Rescheduled',
	AppointmentScheduled = 'Appointment Scheduled',
	ClosedLost = 'Closed Lost',
	ClosedWon = 'Closed Won',
}

export enum DemoStatusFilter {
	DemosToConfirm = 'DemosToConfirm',
	DemosToReschedule = 'DemosToReschedule',
	UpcomingDemos = 'UpcomingDemos',
}

export enum TranscriptionStatus {
	Failed = 'Failed',
	InProgress = 'InProgress',
	Queued = 'Queued',
	Completed = 'Completed',
	Unknown = 'Unknown',
	Available = 'Available',
}

export enum TranscriptionProvider {
	Amazon = 'Amazon',
}

export interface ITranscription extends IBaseApiModel {
	creationDate?: string;
	creator?: IUser;
	id?: string;
	lastModifiedDate?: string;
	numberOfChannels?: number;
	read?: boolean;
	status?: TranscriptionStatus;
	transcriptionProvider?: TranscriptionProvider;
	transcriptionProviderId?: string;
}

export interface ISpeakerLine {
	speaker: string;
	line: string;
	startTimeOffset: number;
	endTimeOffset: number;
}

export interface ITranscriptionSpeakerLines extends IBaseApiModel {
	speakerLines: ISpeakerLine[];
	transcriptionId?: string;
	transcriptionViewType?: string;
}

export interface IPhoneCallCompletedEvent extends INoteEvent {
	phoneCall?: IPhoneCall;
}
