import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../../models/hooks/appStateHooks';

export function useCreateScorecardFromTemplateMutation({
	onError,
}: {
	onError?: (err: Api.IOperationResultNoValue) => void;
}) {
	const userSession = useUserSession();
	return useMutation({
		onError,
		mutationFn: async ({
			templateId,
			phoneCallId,
		}: {
			templateId: string;
			phoneCallId: string;
		}) => {
			const response = await userSession.webServiceHelper.callWebServiceAsync<Api.IScorecard>(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: `scorecard/fromTemplate`,
				}),
				'POST',
				{
					phoneCallId,
					templateId,
				}
			);
			return response.value;
		},
	});
}
