import { Category } from '../../../CategoryListBuilder';
import { Group } from '../Group';
import { IPhoneCallCategory } from '@ViewModels';
import * as React from 'react';

interface IProps {
	categories: IPhoneCallCategory[];
}

export const CategoryList: React.FC<IProps> = ({ categories }) => {
	const hasMoreThanOneCategories = categories && categories?.length > 1;

	const categoriesLength = categories ? categories?.length : 0;

	const hasCategories = categoriesLength > 0;

	const firstCategory = hasCategories ? categories[0] : null;

	return (
		<Group vCentered={true} spacingSize={5}>
			{hasCategories && <Category value={firstCategory?.name || 'Not found'} color={firstCategory?.hexColor} />}
			{hasMoreThanOneCategories && <small>+ {categoriesLength - 1}</small>}
		</Group>
	);
};
