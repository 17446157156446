import { useMutation } from '@tanstack/react-query';
import * as Api from '../../../extViewmodels';
import { useUserSession } from '../../../models/hooks/appStateHooks';

export const useAdminAccountExportOpenInvoicesMutation = ({
	onError,
	onSuccess,
}: {
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: (data: Api.ISystemJob) => void;
} = {}) => {
	const userSession = useUserSession();

	return useMutation({
		mutationFn: () => {
			return userSession.webServiceHelper.callAsync<Api.ISystemJob>(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: 'admin/account/openInvoices/export',
				}),
				'POST',
				{}
			);
		},
		onError,
		onSuccess,
	});
};
