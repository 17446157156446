import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';
import Waypoint from 'react-waypoint';
import { v4 as uuid } from 'uuid';
import {
	ErrorMessagesViewModelKey,
	IErrorMessageComponentProps,
	IUserSessionComponentProps,
	UserSessionViewModelKey,
} from '../../../../models/AppState';
import { IEventLoggingComponentProps, withEventLogging } from '../../../../models/Logging';
import { destructive } from '../../../styles/colors';
import { baseStyleSheet } from '../../../styles/styles';
import { LoadingSpinner } from '../../LoadingSpinner';
import { DangerIcon } from '../../svgs/icons/DangerIcon';
import { MessageBubble } from '../MessageBubble';
import { MultiPhoneSelect } from '../MultiPhoneSelect';
import { useMissedTextMessages } from './hooks';
import { styleSheet } from './styles';

interface IProps extends IErrorMessageComponentProps, IEventLoggingComponentProps, IUserSessionComponentProps {
	className?: string;
	conversation?: Api.ConversationViewModel;
	onTryNewPhoneNumber?: (phoneNumber: Api.IPhoneNumber) => void;
	phoneNumber?: Api.ITelephonyConfiguration;
	placeholderMessages?: (string | JSX.Element)[];
	processing?: boolean;
	recipient?: Api.TextRecipientViewModel;
}

class ConversationBase extends React.Component<IProps> {
	private containerRef: React.RefObject<HTMLDivElement>;
	private offset: number;
	private uuid: string;

	private typingInidicatorVm: Api.TypingIndicatorEventsViewModel;

	constructor(props: IProps) {
		super(props);
		this.containerRef = React.createRef();
		this.offset = 0;
		this.uuid = uuid();
	}

	public async componentDidMount() {
		const { errorMessages, logApiError, userSession, phoneNumber } = this.props;
		this.getMessages();
		this.markAsRead();
		if (phoneNumber) {
			this.typingInidicatorVm = new Api.TypingIndicatorEventsViewModel(userSession, phoneNumber);
			try {
				await this.typingInidicatorVm.connect();
			} catch (error) {
				const apiError = Api.asApiError(error);

				logApiError('InitTypingIndicator-Error', apiError);
				if (apiError.systemMessage) {
					errorMessages.pushApiError(apiError);
				}
			}
		}
	}

	public componentDidUpdate(prevProps: IProps) {
		const { conversation } = this.props;

		if (prevProps.conversation?.id !== conversation?.id) {
			this.getMessages();
			this.markAsRead();
			return;
		}

		if (conversation?.unreadMessageCount) {
			this.markAsRead();
		}

		this.containerRef.current.scrollTop = this.offset;
	}

	public componentWillUnmount() {
		this.typingInidicatorVm?.permananentlyDisconnect();
	}

	public getSnapshotBeforeUpdate() {
		this.offset = this.containerRef.current.scrollTop;
		return this.offset;
	}

	private getMessages = () => {
		const { conversation, errorMessages, logApiError } = this.props;
		conversation?.getMessages().catch(err => {
			logApiError('LoadConversationTextMessages-Error', err);

			errorMessages.pushApiError(err);
		});
	};

	private markAsRead = () => {
		const { conversation, errorMessages, logApiError } = this.props;
		conversation?.markAsRead().catch(err => {
			logApiError('ConversationMarkAsRead-Error', err);

			errorMessages.pushApiError(err);
		});
	};

	private renderMessages() {
		const { conversation, placeholderMessages, processing, recipient, onTryNewPhoneNumber } = this.props;
		if (processing || conversation?.isFetching) {
			return null;
		}

		if (
			!conversation?.isFetching &&
			!conversation?.isSending &&
			!conversation?.isBusy &&
			conversation?.isLoaded &&
			conversation?.textMessages.length === 0
		) {
			return <div className={css(styleSheet.noMessages)}>No messages yet</div>;
		}

		const dateSepsAdded = new Set();
		if (conversation?.textMessages.length) {
			const today = moment();
			const yesterday = today.clone().subtract(1, 'day');

			// get the last message that was sent so can show it's status below message bubble.
			const lastSentMessage = [...conversation.textMessages.toArray()].find(
				t => t.direction === Api.Direction.Outbound
			);

			let messages: JSX.Element[] = [];

			conversation.textMessages.forEach((t, i, arr) => {
				const messageDate = moment(t.creationDate);
				let dateSep: JSX.Element;

				// show a line separator between dates or at the top if first message sent was not sent today

				if (!messageDate.isSame(moment(arr[i + 1]?.creationDate), 'day') || i === arr.length - 1) {
					const text = messageDate.isSame(today, 'day')
						? 'Today'
						: messageDate.isSame(yesterday, 'day')
							? 'Yesterday'
							: messageDate.format('MMM Do, yyyy');
					if (!dateSepsAdded.has(text)) {
						dateSepsAdded.add(text);
						dateSep = (
							<div className={css(styleSheet.dateSeparator)}>
								<span>{text}</span>
							</div>
						);
					}
				}

				if (t.media) {
					t.media.forEach((m, ii, rr) => {
						messages.push(
							<div key={`text-${t.id}-${ii}`}>
								{!t.text && dateSep}
								<MessageBubble
									className='message'
									entity={
										t.direction === Api.Direction.Inbound &&
										conversation.toNumbers.find(num => num.number.e164 === t.owner.e164)?.contact
									}
									id={`text-${t.id}-${ii}`}
									mediaContent={m}
									showAvatar={conversation.toNumbers.length > 1}
									showStatus={
										t.id === lastSentMessage?.id && !t.errorMessage && !placeholderMessages && ii === rr.length - 1
									}
									showTimeStamp={true}
									textMessage={t}
								/>
							</div>
						);
					});
				}

				if (t.text) {
					messages.push(
						<div key={t.id}>
							{dateSep}
							<MessageBubble
								className='message'
								entity={
									t.direction === Api.Direction.Inbound &&
									conversation.toNumbers.find(num => num.number.e164 === t.owner.e164)?.contact
								}
								id={`text-${t.id}`}
								showAvatar={conversation.toNumbers.length > 1}
								showStatus={t.id === lastSentMessage?.id && !t.errorMessage && !placeholderMessages && !t.media}
								showTimeStamp={true}
								textMessage={t}
							/>
						</div>
					);
				}
			});

			const lastMessage = conversation.textMessages.getByIndex(0);
			if (
				!!lastMessage?.errorMessage &&
				!lastMessage?.canRetry &&
				recipient?.phoneNumbers?.length > 1 &&
				onTryNewPhoneNumber
			) {
				const currentNumber = recipient.phoneNumbers.filter(p => {
					return conversation.toNumbers.filter(t => p.metadata?.standard === t.number?.standard).length > 0;
				});

				const otherPhoneNumbersSelect = (
					<div key='other-phone-numbers' className={css(styleSheet.otherPhoneNumbers)}>
						<span>Next, try this number:</span>
						<MultiPhoneSelect
							ctaLabel='Try this number'
							exclusions={currentNumber}
							onPhoneNumberSelected={onTryNewPhoneNumber}
							recipient={recipient}
						/>
					</div>
				);

				messages = [otherPhoneNumbersSelect, ...messages];
			}

			if (
				!!recipient?.automatedSmsOptOutMetadata ||
				(conversation.toNumbers.length === 1 && !!conversation.automatedSMSOptOutDate) ||
				recipient?.phoneNumbers?.find(x => x.metadata?.e164 === conversation.toNumbers[0].number.e164)
					?.canSendAutomatedSMS === Api.TextMessageCapability.OptOut
			) {
				messages = [
					<div key='unsubscribe-warning' className={css(baseStyleSheet.horizontalStack)}>
						<DangerIcon />
						<span style={{ color: destructive }}>This contact has requested to be unsubscribed</span>
					</div>,
					...messages,
				];
			}

			if (this.typingInidicatorVm?.usersTyping?.length > 0) {
				// TODO: Add a hover state, in the case multiple users are typing, that pops something up that enumerates the names?
				const message =
					this.typingInidicatorVm.usersTyping.length > 1
						? 'Multiple users typing'
						: `${this.typingInidicatorVm.usersTyping[0].firstName} is typing`;
				messages = [
					<div key='typingIndicator'>
						<MessageBubble
							className='message'
							customLabel={message}
							id='typingIndicator'
							textMessage={{ direction: Api.Direction.Outbound, text: '...' }}
						/>
					</div>,
					...messages,
				];
			}

			if (placeholderMessages) {
				messages = [...placeholderMessages.map(p => <div key={uuid()}>{p}</div>), ...messages];
			}

			return !conversation?.allMessagesLoaded && !conversation?.isFetching
				? [...messages, <Waypoint key={this.uuid} topOffset='-200px' onEnter={this.getMessages} />]
				: messages;
		}
	}

	public render() {
		const { conversation, processing, className = '' } = this.props;
		const messages = this.renderMessages();
		return (
			<div className={`${css(styleSheet.container)} ${className}`} ref={this.containerRef}>
				{messages}
				{(conversation?.isFetching || processing) && <LoadingSpinner />}
			</div>
		);
	}
}

const ConversationAsObserver = observer(ConversationBase);
const ConversationWithLogger = withEventLogging((props: IProps) => {
	useMissedTextMessages({ conversation: props.conversation });
	return <ConversationAsObserver {...props} />;
});
export const Conversation = inject(ErrorMessagesViewModelKey, UserSessionViewModelKey)(ConversationWithLogger);
