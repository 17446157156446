import { StyleSheet } from 'aphrodite';
import { FontFamilies } from '../../../../web/styles/styles';
import { background, brandPrimaryHover, destructive, success, white } from '../../../styles/colors';

export const styleSheet = StyleSheet.create({
	container: {
		display: 'flex',
		flexDirection: 'column',
		gap: 4,
		background: white,
		borderRadius: 8,
		width: '100%',
	},
	progressions: {
		display: 'flex',
		flexWrap: 'wrap',
		gap: 8,
		paddingBottom: 8,
		paddingLeft: 16,
		paddingRight: 16,
	},
	progression: {
		display: 'flex',
		alignItems: 'center',
		width: '30%',
		gap: 8,
	},
	isHovering: {
		fontFamily: FontFamilies.bold,
	},
	header: {
		textTransform: 'uppercase',
		display: 'flex',
		justifyContent: 'space-between',
		borderTopLeftRadius: 8,
		borderTopRightRadius: 8,
		background,
		gap: 6,
		color: brandPrimaryHover,
		padding: 8,
	},
	footer: {
		display: 'flex',
		justifyContent: 'center',
		background,
		borderBottomLeftRadius: 8,
		borderBottomRightRadius: 8,
		padding: 8,
		gap: 6,
	},
	redCircle: {
		color: destructive,
		fontSize: 8,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
	},
	greenCircle: {
		color: success,
		fontSize: 8,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
	},
	green: {
		fontWeight: 600,
		color: success,
	},
	red: {
		fontWeight: 600,
		color: destructive,
	},
	oneQuarter: {
		width: '25%',
		alignItems: 'center',
	},
	oneHalf: {
		width: '50%',
		display: 'flex',
		gap: 8,
	},
	currentScore: {
		display: 'flex',
		justifyContent: 'center',
		gap: 6,
	},
	topicPopover: {
		padding: 12,
		maxWidth: 600,
		whiteSpace: 'pre-line',
	},
});
