import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../web/ReactQueryProvider';

const BASE_QUERY_KEY = 'survey/eventregistration/:id/stats';

export function useEventRegistrationStats({
	impersonationContext,
	enabled = true,
	refetchOnWindowFocus = false,
	surveyId,
}: {
	impersonationContext?: Api.IImpersonationContext;
	enabled?: boolean;
	surveyId: string;
	refetchOnWindowFocus?: boolean;
}) {
	const userSession = useUserSession();
	return useQuery({
		enabled,
		queryFn: () => {
			return userSession.webServiceHelper.callAsync<Api.IEventRegistrationSurvey>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: `survey/eventregistration/${surveyId}/stats`,
				}),
				'POST'
			);
		},
		queryKey: [BASE_QUERY_KEY, surveyId],
		refetchOnWindowFocus,
	});
}

export const invalidateEventRegistrationStats = (surveyId: string) => {
	reactQueryClient.invalidateQueries([BASE_QUERY_KEY, surveyId]);
};
