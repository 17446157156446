import { StyleSheet } from 'aphrodite';
import { FontFamilies, baseStyles } from '../../../../../web/styles/styles';
import { brandSecondary, titles, white } from '../../../../styles/colors';
import { aidaBaseStyles } from '../../../../styles/styles';

export const styleSheet = StyleSheet.create({
	columnDate: {
		width: 200,
	},
	columnDay: {
		width: 126,
	},
	columnHeader: {
		borderTopLeftRadius: 10,
		borderTopRightRadius: 10,
		padding: '8px 20px',
		textAlign: 'left',
	},
	actionsColumn: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	columnThin: {
		width: '8%',
	},
	columnTiny: {
		width: '5%',
	},
	container: {
		background: white,
		borderCollapse: 'collapse',
		borderRadius: 10,
		margin: '0 40px 0 40px',
		minHeight: 100,
		padding: 20,
		tableLayout: 'fixed',
		width: 'calc(100% - 80px)',
	},
	extraData: {
		background: white,
		borderRadius: 10,
		bottom: 20,
		boxShadow: '0 0 4px 4px rgba(0,0,0,0.1)',
		padding: 20,
		position: 'absolute',
		right: 20,
		width: 400,
	},
	extraDataKey: {
		fontFamily: FontFamilies.bold,
	},
	extraDataName: {
		color: titles,
		fontFamily: FontFamilies.bold,
		fontSize: 20,
	},
	extraDataRow: {
		...aidaBaseStyles.flexHorizontalCenter,
		justifyContent: 'space-between',
	},
	extraDataValue: {},
	headerContainer: {
		background: white,
		borderTopLeftRadius: 10,
		borderTopRightRadius: 10,
		position: 'sticky',
		top: 0,
	},
	rowContainer: {},
	rowData: {
		padding: '8px 20px',
		...baseStyles.truncateText,
	},
	rowDataInteractive: {
		...aidaBaseStyles.brandLink,
		':hover': {
			background: brandSecondary,
			color: 'white',
		},
	},
});
