import * as Api from '@ViewModels';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../web/ReactQueryProvider';

const EVENT_REG_RESPONSES_FILTER_QUERY_KEY = 'survey/eventRegistration/response/filter';

export function useEventRegistrationResponsesFilter({
	enabled,
	filterRequest,
	impersonationContext,
	pageSize = 25,
	refetchOnWindowFocus,
}: {
	enabled?: boolean;
	filterRequest: Api.IEventRegistrationSurveyResponseFilterRequest;
	impersonationContext?: Api.IImpersonationContext;
	pageSize?: number;
	refetchOnWindowFocus?: boolean;
}) {
	const userSession = useUserSession();
	return useInfiniteQuery<Api.IPagedCollection<Api.IEventRegistrationSurveyResponse>>({
		enabled,
		getNextPageParam: lastPage => lastPage.pageToken,
		queryFn: async ({ pageParam }) => {
			return userSession.webServiceHelper.callAsync<Api.IPagedCollection<Api.IEventRegistrationSurveyResponse>>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					queryParams: { pageSize, pageToken: pageParam },
					urlPath: `survey/eventRegistration/response/filter`,
				}),
				'POST',
				filterRequest
			);
		},
		queryKey: [EVENT_REG_RESPONSES_FILTER_QUERY_KEY, filterRequest, pageSize, impersonationContext],
		refetchOnWindowFocus,
	});
}

export const invalidateEventRegistrationResponsesFilter = () => {
	reactQueryClient.invalidateQueries([EVENT_REG_RESPONSES_FILTER_QUERY_KEY]);
};
