import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../../models/hooks/appStateHooks';

export const ADMIN_ACCOUNT_OPEN_INVOICES_QUERY_KEY = 'admin/account/openInvoices';

export function useAdminAccountOpenInvoicesQuery({
	onError,
	onSuccess,
}: {
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: (account: Api.IAccountOpenInvoices[]) => void;
}) {
	const userSession = useUserSession();
	return useQuery({
		onError,
		onSuccess,
		queryFn: () =>
			userSession.webServiceHelper.callAsync<Api.IAccountOpenInvoices[]>(`admin/account/openInvoices`, 'GET'),
		queryKey: [ADMIN_ACCOUNT_OPEN_INVOICES_QUERY_KEY],
	});
}
