import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export const ATTACHMENT_VIDEO_QUERY_KEY = 'attachmentVideoQuery';

export function useAttachmentQuery({
	id,
	enabled,
	impersonationContext,
}: { id: string; enabled: boolean; impersonationContext?: Api.IImpersonationContext }) {
	const userSession = useUserSession();

	return useQuery({
		enabled,
		queryFn: async () => {
			return userSession.webServiceHelper.callAsync<Api.IFileAttachment>(
				Api.ImpersonationBroker.composeApiUrl({ impersonationContext, urlPath: `attachment/${id}` }),
				'GET'
			);
		},
		queryKey: [ATTACHMENT_VIDEO_QUERY_KEY, id],
	});
}
