import { FilterRequestBase, IFileAttachment, IFilterCriteria } from '.';

export enum TelephonyServiceProvider {
	Unknown = 'Unknown',
	Bandwidth = 'Bandwidth',
	Twilio = 'Twilio',
	Dialpad = 'Dialpad',
}

export enum CampaignRegistrationStatus {
	Accepted = 'Accepted',
	Deactivated = 'Deactivated',
	Declined = 'Declined',
	Failed = 'Failed',
	Pending = 'Pending',
	Verified = 'Verified',
}

export enum TextingBrandStatus {
	Pending = 'Pending',
	Unverified = 'Unverified',
	Verified = 'Verified',
	Failed = 'Failed',
}

export type CompanyAddress = {
	sid?: string;
	supportingDocumentSid?: string;
	street?: string;
	city?: string;
	stateProvince?: string;
	postalCode?: string;
	country?: string;
};

export type BusinessInformation = {
	sid?: string;
	legalBusinessName?: string;
	type: string;
	ein?: string;
	industry?: string;
	stockExchange?: string;
	stockSymbol?: string;
	domain?: string;
};

export enum CampaignConsentType {
	Online = 'Online',
	OfflineForm = 'OfflineForm',
	OfflineVerbal = 'OfflineVerbal',
}

export type CampaignConsent = {
	type?: CampaignConsentType;
	optInScript?: string;
	consentFormUrl?: string;
};

export type CampaignOptions = {
	consent?: CampaignConsent;
	privacyPolicyUrl?: string;
	isDirectLender: boolean;
	includesEmbeddedPhone: boolean;
	embeddedPhoneSample?: string;
	optInFlow?: string;
	optInFlowImage?: IFileAttachment;
};

export type BusinessRepresentative = {
	sid?: string;
	email?: string;
	firstName?: string;
	lastName?: string;
	phoneNumber?: string;
	mobilePhoneNumber?: string;
	businessTitle?: string;
	jobPosition: string;
};

export type ClientProfile = {
	address?: CompanyAddress;
	businessInformation?: BusinessInformation;
	campaignOptions?: CampaignOptions;
	primaryRepresentative?: BusinessRepresentative;
	secondaryRepresentative?: BusinessRepresentative;
	registrationRequirementsCertified: boolean;
	registrationRequirementsCertifiedAt?: string;
	registrationRequirementsCertifiedBy?: string;
};

type TextingBrand = {
	id?: string;
	status: TextingBrandStatus;
	errorMessage?: string;
	creationDate: string;
};

export type TextingProviderCampaign = {
	id?: string;
	status: CampaignRegistrationStatus;
	provider: TelephonyServiceProvider;
	lastSubmissionDate: Date;
	errorMessage?: string;
};

export type CampaignRegistration = {
	lastModifiedDate: string;
	telephonyProviderCampaignStatuses: Record<TelephonyServiceProvider, CampaignRegistrationStatus>;
	brand?: TextingBrand;
	campaigns?: TextingProviderCampaign[];
};

export type TelephonyServiceProviderConfiguration = {
	id?: string;
	clientProfile?: ClientProfile;
	campaignRegistration?: CampaignRegistration;
	forceAllowClientProfileEdit?: boolean;
};

export enum TelephonyConfigurationFilterProperty {
	PhoneNumberScope = 'PhoneNumberScope',
	Capability = 'Capability',
	ConnectionState = 'ConnectionState',
	PhoneNumber = 'PhoneNumber',
}

export type TelephonyConfigurationFilterCriteria = IFilterCriteria<TelephonyConfigurationFilterProperty>;

export type TelephonyConfigurationFilter = FilterRequestBase<
	TelephonyConfigurationFilterProperty,
	TelephonyConfigurationFilterCriteria
>;

export interface TextSettings {
	verifiedCampaignRequiredToText: boolean;
	canRequestTextingPhoneNumber: boolean;
	requiresCampaignRegistration: boolean;
	customerSuccessManagerEmailAddress: string;
}
