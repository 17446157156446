import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export function useUploadVideoAttachmentMutation() {
	const userSession = useUserSession();

	return useMutation({
		mutationFn: async ({
			request,
			forUserId,
			impersonationContext,
		}: { request: Api.IPresignedUrlRequest; forUserId: string; impersonationContext?: Api.IImpersonationContext }) => {
			return userSession.webServiceHelper.callAsync<Api.IMediaConvertPresignedResult>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					queryParams: { forUserId },
					urlPath: `attachment/videoUploadUrl`,
				}),
				'POST',
				request
			);
		},
	});
}
