import { css } from 'aphrodite';
import { LoadingSpinner } from '../../../../web/components/LoadingSpinner';
import { baseStyleSheet } from '../../../../web/styles/styles';
import { BasicPage } from '../../BasicPage';
import { styleSheet } from './styles';
import { ScorecardTemplatesTable } from './ScorecardTemplatesTable';
import { TwilioContextProvider } from '../../../components/ai/context';

export const SalesAiSettings = () => {
	const isLoading = false;

	if (isLoading) {
		return <LoadingSpinner className={css(baseStyleSheet.absoluteCenter)} />;
	}

	return (
		<BasicPage className={css(styleSheet.container)} title='Sales AI Settings'>
			<TwilioContextProvider>
				<ScorecardTemplatesTable />
			</TwilioContextProvider>
		</BasicPage>
	);
};
