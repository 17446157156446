import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../../web/ReactQueryProvider';

const BASE_QUERY_KEY = 'transcription';

export function useGetTranscriptionQuery({
	transcriptionId,
}: {
	transcriptionId: string;
}) {
	const userSession = useUserSession();
	return useQuery({
		enabled: !!transcriptionId,
		queryFn: async () => {
			const result = await userSession.webServiceHelper.callAsync<Api.ITranscription>(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: `transcription/${transcriptionId}`,
				}),
				'GET'
			);
			return result;
		},
		queryKey: [BASE_QUERY_KEY, transcriptionId],
		refetchOnWindowFocus: false,
	});
}

export function useGetTranscriptionSpeakerLineViewQuery({
	transcriptionId,
}: {
	transcriptionId: string;
}) {
	const userSession = useUserSession();
	return useQuery({
		enabled: !!transcriptionId,
		queryFn: async () => {
			const result = await userSession.webServiceHelper.callAsync<Api.ITranscriptionSpeakerLines>(
				Api.ImpersonationBroker.composeApiUrl({
					queryParams: { type: 'SpeakerLine' },
					urlPath: `transcription/${transcriptionId}/view`,
				}),
				'GET'
			);
			return result;
		},
		queryKey: [BASE_QUERY_KEY, transcriptionId],
		refetchOnWindowFocus: false,
	});
}

export function invalidateGetTranscription(transcriptionId: string) {
	return reactQueryClient.invalidateQueries([BASE_QUERY_KEY, transcriptionId]);
}
