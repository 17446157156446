import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export function useEventRegistrationResponseExport() {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: ({
			filterRequest,
			impersonationContext,
		}: {
			filterRequest?: {
				surveyId: string;
				filter: Api.IEventRegistrationSurveyResponseFilterRequest;
			};
			impersonationContext?: Api.IImpersonationContext;
		}) => {
			return userSession.webServiceHelper.callAsync<Api.ISystemJob>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: `survey/eventRegistration/response/export`,
				}),
				'POST',
				filterRequest
			);
		},
	});
}
