import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../../models/hooks/appStateHooks';

export function useCreateScorecardTemplatePhrasesMutation() {
	const userSession = useUserSession();

	return useMutation({
		mutationFn: async ({
			templateId,
			topics,
		}: {
			templateId: string;
			topics: Api.IScorecardTemplatePhraseTopics[];
		}) => {
			const response = await userSession.webServiceHelper.callWebServiceAsync(
				Api.ImpersonationBroker.composeApiUrl({ urlPath: `scorecardTemplate/${templateId}/phrases` }),
				'PUT',
				{ topics }
			);
			return response.value;
		},
	});
}
