import { MrryCloseButton } from '../MrryCloseButton';
import { SpeechBubble, SpeechBubbleType } from '../SpeechBubble';
import { Mrry } from '../svgs/Mrry';
import { styleSheet } from './styles';
import { animated, config, useSpring } from '@react-spring/web';
import { css } from 'aphrodite';
import { CSSProperties, useCallback, useEffect, useRef, useState } from 'react';
import * as React from 'react';

export enum MrryDirection {
	FromTop = 'fromTop',
	FromRight = 'fromRight',
	FromRightBottom = 'fromRightBottom',
	FromRightTop = 'fromRightTop',
	FromBottom = 'fromBottom',
	FromLeft = 'fromLeft',
	FromLeftBottom = 'fromLeftBottom',
	FromLeftTop = 'fromLeftTop',
}

export enum MrryMessageType {
	Info = 'info',
	Error = 'error',
	Normal = 'normal',
	Success = 'success',
}

export interface IMrryMessage {
	content: string | JSX.Element | React.ReactNode;
	/**
	 * How long the mrry message should be displayed (in ms). if "until-closed" is provided, the modal will not close
	 * automatically. instead it will require that the user close it manually.
	 *
	 * @default 5000
	 */
	duration?: number;
	type?: MrryMessageType;
}

interface IProps {
	className?: string;
	/** Determines form what direction the modal will appear if not provided, the direction will be determined randomly */
	fromDirection: MrryDirection;
	internalCloseButton?: boolean;
	onRequestClose?: () => void;
	message: IMrryMessage;
}

const getBubbleDirection = (direction: MrryDirection) => {
	const from: CSSProperties = { opacity: 0 };
	const to: CSSProperties = { opacity: 1 };

	const x = direction === MrryDirection.FromLeft ? -10 : 10;

	from.transform = `translate3d(${x}, 0, 0)`;
	to.transform = 'translate3d(0, 0, 0)';

	return [from, to];
};

const getDirection = (direction: MrryDirection): [CSSProperties, CSSProperties, MrryDirection] => {
	const from: CSSProperties = { opacity: 0 };
	const to: CSSProperties = { opacity: 1 };

	switch (direction) {
		case MrryDirection.FromRight:
			from.transform = 'translate3d(40px, -50%, 5px)';
			to.transform = 'translate3d(0, -50%, 5px)';
			break;
		case MrryDirection.FromRightBottom:
		case MrryDirection.FromRightTop:
			from.transform = 'translate3d(40px, 0, 5px)';
			to.transform = 'translate3d(0, 0, 5px)';
			break;
		case MrryDirection.FromBottom:
			from.transform = 'translate3d(0, 40px, 5px)';
			to.transform = 'translate3d(0, 0, 5px)';
			break;
		case MrryDirection.FromLeft:
			from.transform = 'translate3d(-40px, -50%, 5px)';
			to.transform = 'translate3d(0, -50%, 5px)';
			break;
		case MrryDirection.FromLeftBottom:
		case MrryDirection.FromLeftTop:
			from.transform = 'translate3d(-40px, 0, 5px)';
			to.transform = 'translate3d(0, 0, 5px)';
			break;
		default:
			from.transform = 'translate3d(-50%, -40px, 5px)';
			to.transform = 'translate3d(-50%, 0, 5px)';
			break;
	}

	return [from, to, direction];
};

export const MrryModal: React.FC<IProps> = ({
	className = '',
	fromDirection = MrryDirection.FromTop,
	internalCloseButton,
	message,
	onRequestClose,
}) => {
	const [from, to, direction] = useRef(getDirection(fromDirection)).current;
	const [bubbleFrom, bubbleTo] = useRef(getBubbleDirection(direction)).current;
	const [durationTimeout, setDurationTimeout] = useState<number>(null);
	const isLeft = useRef(
		direction === MrryDirection.FromLeft ||
			direction === MrryDirection.FromLeftBottom ||
			direction === MrryDirection.FromLeftTop
	).current;
	const spring = useSpring({
		config: config.gentle,
		delay: 100,
		from,
		to,
	});
	const bubbleSpring = useSpring({
		config: config.gentle,
		delay: 500,
		from: bubbleFrom,
		to: bubbleTo,
	});

	useEffect(() => {
		if (message?.duration) {
			setDurationTimeout(window.setTimeout(onRequestClose, message.duration));
		}

		return () => {
			window.clearTimeout(durationTimeout);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getBubbleType = useCallback(() => {
		switch (message?.type) {
			case MrryMessageType.Info:
				return SpeechBubbleType.Info;
			case MrryMessageType.Error:
				return SpeechBubbleType.Error;
			case MrryMessageType.Success:
				return SpeechBubbleType.Success;
			default:
				return SpeechBubbleType.Normal;
		}
	}, [message]);

	const onCloseClick = () => {
		window.clearTimeout(durationTimeout);
		onRequestClose?.();
	};

	return (
		<animated.div
			className={`${css(styleSheet.mrryModalContainer, styleSheet[direction])} ${className}`}
			style={spring}
		>
			<animated.div style={bubbleSpring}>
				<SpeechBubble
					className={css(styleSheet.speechBubble)}
					onCloseClick={onRequestClose ? onCloseClick : null}
					tailSide={isLeft ? 'left' : 'right'}
					type={getBubbleType()}
				>
					{message?.content}
					{!!onRequestClose && internalCloseButton && (
						<MrryCloseButton
							className={css(styleSheet.closeButton, styleSheet.closeRight, styleSheet.closeRightInternal)}
							onClick={onCloseClick}
						/>
					)}
				</SpeechBubble>
			</animated.div>
			<div
				className={css(styleSheet.mrryContainer, isLeft ? styleSheet.mrryContainerLeft : styleSheet.mrryContainerRight)}
			>
				{!!onRequestClose && !internalCloseButton && (
					<MrryCloseButton
						className={css(styleSheet.closeButton, isLeft ? styleSheet.closeLeft : styleSheet.closeRight)}
						onClick={onCloseClick}
					/>
				)}
				<Mrry className={css(styleSheet.mrry)} />
			</div>
		</animated.div>
	);
};
