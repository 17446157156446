import { useQuery } from '@tanstack/react-query';
import * as Api from '@ViewModels';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../web/ReactQueryProvider';
import { useCreateCustomPageStyleMutation } from './useCreateCustomPageStyleMutation';

const BASE_QUERY_KEY = 'CustomPageStyle/css/{type}';

export const useGetCssByPageTypeQuery = ({
	type,
	impersonationContext,
	enabled,
	refetchOnWindowFocus,
	allowedClasses,
	onSuccess,
}: {
	type: Api.CustomPageType;
	impersonationContext?: Api.IImpersonationContext;
	enabled?: boolean;
	refetchOnWindowFocus?: boolean;
	allowedClasses?: string[];
	onSuccess?: (data: Api.CustomPageStyle) => void;
}) => {
	const userSession = useUserSession();
	const createMutation = useCreateCustomPageStyleMutation();
	return useQuery({
		enabled,
		refetchOnWindowFocus,
		onSuccess,
		queryFn: async () => {
			try {
				const value = await userSession.webServiceHelper.callAsync<Api.CustomPageStyle>(
					Api.ImpersonationBroker.composeApiUrl({
						urlPath: `CustomPageStyle/css/${encodeURIComponent(type)}`,
						impersonationContext,
					}),
					'GET'
				);
				return value;
			} catch (error) {
				const apiError = Api.asApiError(error);
				if (apiError.systemCode === 404) {
					const cssContent = allowedClasses
						? allowedClasses.reduce((prevContent, cls) => {
								return `${prevContent}${prevContent ? '\n\n' : ''}${cls} {\n}`;
							}, '')
						: '';
					return createMutation.mutateAsync({
						style: {
							css: cssContent,
							type,
						},
						impersonationContext,
					});
				}
				throw apiError;
			}
		},
		queryKey: [BASE_QUERY_KEY, type, impersonationContext],
	});
};

export const invalidateAllCssByPageTypeQueries = () => {
	reactQueryClient.invalidateQueries([BASE_QUERY_KEY]);
};

export const invalidateCssByPageTypeQueries = (type: Api.CustomPageType) => {
	reactQueryClient.invalidateQueries([BASE_QUERY_KEY, type]);
};
