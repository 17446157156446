import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../../models/hooks/appStateHooks';

export function useUpdateEnabledUserIdsMutation() {
	const userSession = useUserSession();

	return useMutation({
		mutationFn: async ({
			templateId,
			enabledUserIds,
		}: {
			templateId: string;
			enabledUserIds: string[];
		}) => {
			const response = await userSession.webServiceHelper.callWebServiceAsync(
				Api.ImpersonationBroker.composeApiUrl({ urlPath: `scorecardTemplate/${templateId}/enabledUserIds` }),
				'PUT',
				{ enabledUserIds }
			);
			return response.value;
		},
	});
}
