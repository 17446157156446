import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../../models/hooks/appStateHooks';

export function useCreateCallSuggestionMutation() {
	const userSession = useUserSession();

	return useMutation({
		mutationFn: async ({
			id,
			suggestions,
		}: {
			id: string;
			suggestions: Api.ICallSuggestion[];
		}) => {
			const response = await userSession.webServiceHelper.callWebServiceAsync(
				Api.ImpersonationBroker.composeApiUrl({ urlPath: `callSuggestions/${id}/suggestions` }),
				'PUT',
				{ suggestions }
			);
			return response.value;
		},
	});
}
