import * as Api from '@ViewModels';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../web/ReactQueryProvider';

const INFINITE_SURVEYS_QUERY_KEY = 'infiniteSurveys/satisfaction';

export function useInfiniteSatisfactionSurveys({
	shouldIncludeArchived = false,
	pageSize = 100,
	impersonationContext,
}: {
	shouldIncludeArchived?: boolean;
	pageSize?: number;
	impersonationContext?: Api.IImpersonationContext;
	transformer?: (page: Api.IPagedCollection<Api.ISatisfactionSurvey>) => Api.IPagedCollection<Api.ISatisfactionSurvey>;
}) {
	const userSession = useUserSession();
	return useInfiniteQuery<Api.IPagedCollection<Api.ISatisfactionSurvey>>({
		getNextPageParam: lastPage => lastPage.pageToken,
		queryFn: async ({ pageParam }) => {
			const page = await userSession.webServiceHelper.callAsync<Api.IPagedCollection<Api.ISatisfactionSurvey>>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					queryParams: { includeArchived: shouldIncludeArchived, pageSize, pageToken: pageParam },
					urlPath: `survey/satisfaction`,
				}),
				'GET',
				null
			);

			return page;
		},
		queryKey: [INFINITE_SURVEYS_QUERY_KEY, pageSize, shouldIncludeArchived, impersonationContext],
	});
}

export const invalidateInfiniteSatisfactionSurveys = (impersonationContext?: Api.IImpersonationContext) => {
	reactQueryClient.invalidateQueries([INFINITE_SURVEYS_QUERY_KEY, impersonationContext]);
};
