import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../../web/ReactQueryProvider';

const BASE_QUERY_KEY = 'phoneCall';

export function useGetPhoneCallQuery({
	phoneCallId,
}: {
	phoneCallId: string;
}) {
	const userSession = useUserSession();
	return useQuery({
		enabled: !!phoneCallId,
		queryFn: async () => {
			const result = await userSession.webServiceHelper.callAsync<Api.IPhoneCall>(`phoneCall/${phoneCallId}`, 'GET');
			return result;
		},
		queryKey: [BASE_QUERY_KEY, phoneCallId],
		refetchOnWindowFocus: false,
	});
}

export function invalidateGetPhoneCall(phoneCallId: string) {
	return reactQueryClient.invalidateQueries([BASE_QUERY_KEY, phoneCallId]);
}
