import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../../models/hooks/appStateHooks';

export function useCreateCallSuggestionsMutation({
	onError,
}: {
	onError?: (err: Api.IOperationResultNoValue) => void;
}) {
	const userSession = useUserSession();
	return useMutation({
		onError,
		mutationFn: async ({
			vertical,
			persona,
			leadStage,
		}: {
			vertical: string;
			persona: Api.ScorecardPersona;
			leadStage?: string;
		}) => {
			const response = await userSession.webServiceHelper.callWebServiceAsync(
				Api.ImpersonationBroker.composeApiUrl({ urlPath: `callSuggestions/` }),
				'POST',
				{
					vertical,
					persona,
					leadStage,
				}
			);
			return response.value;
		},
	});
}
