import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../../web/ReactQueryProvider';

const BASE_QUERY_KEY = 'scorecard';

export function useGetScorecardQuery({
	scorecardId,
}: {
	scorecardId: string;
}) {
	const userSession = useUserSession();
	return useQuery({
		enabled: !!scorecardId,
		queryFn: async () => {
			const result = await userSession.webServiceHelper.callAsync<Api.IScorecard>(`scorecard/${scorecardId}`, 'GET');
			return result;
		},
		queryKey: [BASE_QUERY_KEY, scorecardId],
		refetchOnWindowFocus: false,
	});
}

export function invalidateGetScorecard(scorecardId: string) {
	return reactQueryClient.invalidateQueries([BASE_QUERY_KEY, scorecardId]);
}
