import { ViewportContext } from '../../../hooks/useViewport';
import { FieldKey } from '../../../viewModels/form';
import { ILeadViewDescriptionProps, LeadViewDescription } from '../LeadViewDescription';
import { LeadViewFilters } from '../LeadViewFilters';
import { ILeadViewRowElementProps, LeadViewRowElement } from '../LeadViewRowElement';
import { ILeadViewRowViewProps, LeadViewRowView } from '../LeadViewRowView';
import { LeadViewTableContainer } from '../LeadViewTableContainer';
import { ILeadViewTableHeaderProps, LeadViewTableHeader } from '../LeadViewTableHeader';
import { ILeadViewTitleProps, LeadViewTitle } from '../LeadViewTitle';
import { LeadViewViewSelector } from '../LeadViewViewSelector';
import { ILeadViewContext, LeadViewContext, LeadViewType } from '../context';
import { styleSheet } from './styles';
import { IUser } from '@ViewModels';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import { useMemo, FC, useContext, useEffect, useState } from 'react';

interface IProps {
	onUserSelected?: (user: IUser) => void;
	onOutcomeSelected?: (outcome: FieldKey) => void;
	onViewSelected(viewType: LeadViewType): void;
}

interface ILeadViewCompoundComponent {
	Title: FC<ILeadViewTitleProps>;
	Description: FC<ILeadViewDescriptionProps>;
	ViewSelector: FC;
	Filters: FC;
	TableHeader: FC<ILeadViewTableHeaderProps>;
	TableContainer: FC;
	CardView: FC;
	RowView: FC<ILeadViewRowViewProps>;
	RowElement: FC<ILeadViewRowElementProps>;
	EmptyState: FC;
	ErrorState: FC;
}

const LeadViewBase: FC<IProps> & ILeadViewCompoundComponent = props => {
	const { children, onOutcomeSelected, onUserSelected, onViewSelected } = props;
	const [viewType, setViewType] = useState<LeadViewType>(localStorage.getItem('LeadView-ViewType') as LeadViewType);
	const [selectedUser, setSelectedUser] = useState<IUser>(null); // TODO, store in query
	const [selectedOutcome, setSelectedOutcome] = useState<FieldKey>(null); // TODO, store in query
	const [isManager, setIsManager] = useState<boolean>(false); // TODO, store in localstorage

	useEffect(() => {
		onOutcomeSelected?.(selectedOutcome);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedOutcome]);

	useEffect(() => {
		onUserSelected?.(selectedUser);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedUser]);

	useEffect(() => {
		onViewSelected(viewType);
		localStorage.setItem('LeadView-ViewType', viewType || '');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [viewType]);

	const { isBelowSmall } = useContext(ViewportContext);

	const containerMobile = isBelowSmall ? styleSheet.containerMobile : null;
	const value = useMemo<ILeadViewContext>(
		() => ({
			isManager,
			selectedOutcome,
			selectedUser,
			setIsManager,
			setSelectedOutcome,
			setSelectedUser,
			setViewType,
			viewType,
		}),
		[isManager, selectedOutcome, selectedUser, viewType]
	);

	return (
		<LeadViewContext.Provider value={value}>
			<div className={`${css(styleSheet.container, containerMobile)} lead-view`}>{children}</div>
		</LeadViewContext.Provider>
	);
};

LeadViewBase.Title = LeadViewTitle;
LeadViewBase.Description = LeadViewDescription;
LeadViewBase.ViewSelector = LeadViewViewSelector;
LeadViewBase.Filters = LeadViewFilters;
LeadViewBase.TableHeader = LeadViewTableHeader;
LeadViewBase.TableContainer = LeadViewTableContainer;
LeadViewBase.CardView = function CardView() {
	return null;
};
LeadViewBase.RowView = LeadViewRowView;
LeadViewBase.RowElement = LeadViewRowElement;
LeadViewBase.EmptyState = function EmptyState() {
	return null;
};
LeadViewBase.ErrorState = function ErrorState() {
	return null;
};

export const LeadView = observer(LeadViewBase);
