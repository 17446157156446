/* eslint-disable react-hooks/exhaustive-deps */
import { ISpeakerLine, ITranscription } from '../../../../extViewmodels';
import { LoadingSpinner } from '../../../../web/components/LoadingSpinner';
import { useTranscription } from '../../../entities/PhoneCall/useTranscription';
import { FetchStatus } from '../../../entities/utils/useStatesOnFetch';
import { useTimeout } from '../../../hooks/useTimeout';
import { TransparentButton } from '../../TransparentButton';
import { styleSheet } from '../styles';
import { css } from 'aphrodite';
import { useCallback, useEffect, useState } from 'react';
import * as React from 'react';

export const SpeakerLinesComponent: React.FC<{
	isLoading: boolean;
	isRead: boolean;
	isSpeakerLinesReady: boolean;
	isTranscriptionCompleted: boolean;
	speakerLines: ISpeakerLine[];
	transcriptionId: string;
	onClick?: (speakerLine: ISpeakerLine) => void;
}> = ({ isLoading, isRead, isSpeakerLinesReady, isTranscriptionCompleted, speakerLines, transcriptionId, onClick }) => {
	const [showEntire, setEntire] = useState(false);
	const timeout = useTimeout();

	const { markAsRead, status } = useTranscription<ITranscription>();

	const data = speakerLines || [];

	const isLongTranscription = data.length >= 6;

	const handleMarkAsRead = useCallback(() => {
		if (status === FetchStatus.Waiting && transcriptionId) {
			timeout(() => markAsRead(transcriptionId), 200);
		}
	}, [transcriptionId, status]);

	const isMarkedAsRead = isRead !== undefined && isRead;

	useEffect(() => {
		if (!isMarkedAsRead && !isLongTranscription && isTranscriptionCompleted && isSpeakerLinesReady) {
			handleMarkAsRead();
		}
	}, [isLongTranscription, isRead, isSpeakerLinesReady, isTranscriptionCompleted]);

	const speakerLinesEntire: ISpeakerLine[] = data;
	const speakerLinesPartial: ISpeakerLine[] = isLongTranscription ? data.slice(0, 5) : null;

	const handleShowEntire = () => {
		if (!isMarkedAsRead) {
			handleMarkAsRead();
		}
		setEntire(true);
	};

	const renderLines = showEntire || speakerLinesPartial === null ? speakerLinesEntire : speakerLinesPartial;

	const transcriptionIsReady = renderLines?.length > 0;

	return (
		<div className={css(styleSheet.transcriptionContent)}>
			{transcriptionIsReady ? (
				renderLines?.map(speakerLine => {
					if (onClick) {
						return (
							<TransparentButton onClick={() => onClick(speakerLine)} key={speakerLine.startTimeOffset}>
								<p className={css(styleSheet.transcriptionContentLine)}>
									<span className={css(styleSheet.transcriptionContentSpeaker)}>{speakerLine.speaker}:</span>{' '}
									{speakerLine.line}
								</p>
							</TransparentButton>
						);
					}
					return (
						<p key={speakerLine.startTimeOffset} className={css(styleSheet.transcriptionContentLine)}>
							<span className={css(styleSheet.transcriptionContentSpeaker)}>{speakerLine.speaker}:</span>{' '}
							{speakerLine.line}
						</p>
					);
				})
			) : isLoading ? (
				<div className={css(styleSheet.transcriptionContentFooter)}>
					<LoadingSpinner type='small' />
				</div>
			) : (
				<p className={css(styleSheet.transcriptionContentLine)}>Transcription in progress</p>
			)}
			{isLongTranscription && !showEntire && (
				<div className={css(styleSheet.transcriptionContentFooter)}>
					<TransparentButton className={css(styleSheet.regularButton)} onClick={handleShowEntire}>
						Read entire transcription
					</TransparentButton>
				</div>
			)}
		</div>
	);
};
