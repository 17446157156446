import { FontFamilies } from '../../../web/styles/styles';
import { SettingsCardType } from '../../models/setting';
import { brandPrimaryLight, green, hotPink, orange } from '../../styles/colors';
import { aidaBaseStyles } from '../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	blitzes: {
		background: green,
	},
	categories: {
		background: hotPink,
	},
	dashAndReports: {
		background: orange,
	},
	emailSignature: {
		background: brandPrimaryLight,
	},
	emailTemplates: {
		background: orange,
	},
	gamification: {
		background: brandPrimaryLight,
	},
	goals: {
		background: green,
	},
	manageUsers: {
		background: brandPrimaryLight,
	},
	password: {
		background: brandPrimaryLight,
	},
	phoneNumbers: {
		background: green,
	},
	salesAi: {
		backgroundColor: '#00DBDE',
		backgroundImage: 'linear-gradient(135deg, #00DBDE 0%, #FC00FF 100%)',
	},
	salesAiTester: {
		backgroundColor: '#00DBDE',
		backgroundImage: 'linear-gradient(135deg, #00DBDE 0%, #FC00FF 100%)',
	},
	settingsCard: {
		':hover': {
			boxShadow: `0 5px 14px 4px rgba(0, 0, 0, 0.2)`,
			transform: 'scale(1.05)',
		},
		...aidaBaseStyles.flexCenter,
		borderRadius: 10,
		color: '#fff',
		fontFamily: FontFamilies.conqueror,
		fontSize: '54px',
		height: 250,
		margin: '22.5px 12px',
		padding: '50px 40px',
		textAlign: 'center',
		textDecoration: 'none',
		transition: '.2s ease-in-out',
		width: 400,
	},
	settingsCardsContainer: {
		...aidaBaseStyles.flexHorizontalCenter,
		alignItems: 'stretch',
		flexWrap: 'wrap',
		justifyContent: 'space-around',
	},
	[SettingsCardType.PrizesLog]: {
		background: brandPrimaryLight,
	},
	[SettingsCardType.SlotMachinePrizes]: {
		background: orange,
	},
	[SettingsCardType.SlotMachines]: {
		background: brandPrimaryLight,
	},
	startABlitz: {
		background: green,
	},
	utilities: {
		background: brandPrimaryLight,
	},
	voicemail: {
		background: brandPrimaryLight,
	},
});
