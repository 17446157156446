import { StyleSheet } from 'aphrodite';
import { background, orange, success } from '../../../styles/colors';

export const styleSheet = StyleSheet.create({
	floatingButton: {
		position: 'absolute',
		right: 40,
		top: 20,
		zIndex: 1,
	},
	floatingPanel: {
		background,
		borderRadius: 8,
		padding: 8,
		position: 'absolute',
		left: 100,
		top: 160,
		zIndex: 8,
		boxShadow: '0px 0px 16px rgba(139, 139, 139, 1)',
		maxWidth: 466, // 450 + 26 px padding total
	},
	scorecardContainer: {
		width: 450,
	},
	buttonToggleGroup: {
		width: 60,
		display: 'flex',
		height: '100%',
		gap: 8,
	},
	buttonToggle: {
		width: 30,
		textAlign: 'center',
	},
	activeButtonToggle: {
		border: '1px solid black',
	},
	header: {
		display: 'flex',
		gap: 8,
	},
	message: {
		fontSize: 14,
		marginTop: 12,
	},
	suggestionsContainer: {
		padding: 16,
		display: 'flex',
		flexDirection: 'column',
		gap: 16,
		maxHeight: 500,
		overflow: 'auto',
	},
	scorecardSelector: {
		padding: 32,
	},
	pillButton: {
		height: 52,
		width: 200,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	redPill: {
		color: orange,
		borderColor: orange,
		borderRadius: 26,
		background: 'rgba(248, 145, 67, 0.2)',
	},
	greenPill: {
		color: success,
		borderColor: success,
		borderRadius: 26,
		background: 'rgba(113, 172, 54, 0.2)',
	},
	pillButtonGroup: {
		display: 'flex',
		gap: 12,
	},
	paragraph: {
		fontSize: 14,
	},
});
