import * as Api from '@ViewModels';
import { TwilioContextProvider } from '../../ai/context';
import { ScorecardWrapper } from '../../ai/SalesAi';

export const CallDetailsScorecard = ({ phoneCall }: { phoneCall: Api.IPhoneCall }) => {
	return (
		<TwilioContextProvider>
			<ScorecardWrapper phoneCall={phoneCall} />
		</TwilioContextProvider>
	);
};
