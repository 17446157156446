import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

const BASE_QUERY_KEY = 'survey/satisfaction/:id/stats/filter';

export function useSatisfactionFilterStats({
	impersonationContext,
	enabled = true,
	refetchOnWindowFocus = false,
	surveyId,
	request,
}: {
	impersonationContext?: Api.IImpersonationContext;
	enabled?: boolean;
	surveyId: string;
	refetchOnWindowFocus?: boolean;
	request: Api.ISatisfactionSurveyResponseFilterRequest;
}) {
	const userSession = useUserSession();
	return useQuery({
		enabled,
		queryFn: () => {
			return userSession.webServiceHelper.callAsync<Api.ISatisfactionSurveyStats>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: `survey/satisfaction/${surveyId}/stats/filter`,
				}),
				'POST',
				request
			);
		},
		queryKey: [BASE_QUERY_KEY, surveyId],
		refetchOnWindowFocus,
	});
}
