import { CssSelectors } from '../../../web/styles/styles';
import { background, brandPrimaryLight, error, green, navigation } from '../../styles/colors';
import { CSSProperties, StyleSheet } from 'aphrodite';

const messageBubbleTailBase: CSSProperties = {
	borderBottomColor: 'transparent',
	borderTopColor: 'transparent',
	content: '" "',
	position: 'absolute',
	top: '50%',
	transform: 'translate(0, -50%)',
};

export const styleSheet = StyleSheet.create({
	bubbleTypeError: {
		background: error,
		...CssSelectors.allDescendants(
			{
				fill: error,
			},
			'svg polygon'
		),
	},
	bubbleTypeErrorLeft: {
		'::after': {
			borderRightColor: error,
		},
	},
	bubbleTypeErrorRight: {
		'::after': {
			borderLeftColor: error,
		},
	},
	bubbleTypeInfoLeft: {
		'::after': {
			borderRightColor: navigation,
		},
	},
	bubbleTypeInfoRight: {
		'::after': {
			borderLeftColor: navigation,
		},
	},
	bubbleTypeInfo: {
		'::after': {
			borderLeftColor: background,
		},
		background,
		...CssSelectors.allDescendants(
			{
				fill: background,
			},
			'svg polygon'
		),
	},
	bubbleTypeNormal: {
		'::after': {
			borderLeftColor: brandPrimaryLight,
		},
		background: brandPrimaryLight,
		...CssSelectors.allDescendants(
			{
				fill: brandPrimaryLight,
			},
			'svg polygon'
		),
	},
	bubbleTypeNormalLeft: {
		'::after': {
			borderRightColor: brandPrimaryLight,
		},
	},
	bubbleTypeNormalRight: {
		'::after': {
			borderLeftColor: brandPrimaryLight,
		},
	},
	bubbleTypeSuccess: {
		'::after': {
			borderLeftColor: green,
		},
		background: green,
		...CssSelectors.allDescendants(
			{
				fill: green,
			},
			'svg polygon'
		),
	},
	bubbleTypeSuccessLeft: {
		'::after': {
			borderRightColor: green,
		},
	},
	bubbleTypeSuccessRight: {
		'::after': {
			borderLeftColor: green,
		},
	},
	contentWrapper: {
		alignItems: 'center',
		background: 'rgba(255, 255, 255, .65)',
		borderRadius: 10,
		display: 'flex',
		minHeight: 70,
		padding: 20,
	},
	left: {
		'::after': {
			borderLeftColor: 'transparent',
			borderWidth: '37px 22px',
			bottom: 10,
			right: 'calc(100% - 1px)',
		},
		'::before': {
			borderLeftColor: 'transparent',
			borderRightColor: 'rgba(255, 255, 255, .65)',
			borderWidth: '35px 20px',
			bottom: 11,
			right: 'calc(100% - 1px)',
		},
	},
	right: {
		'::after': {
			borderRightColor: 'transparent',
			left: 'calc(100% - 1px)',
		},
		'::before': {
			borderLeftColor: 'rgba(255, 255, 255, .65)',
			borderRightColor: 'transparent',
			left: 'calc(100% - 1px)',
		},
	},
	speechBubbleContainer: {
		'::after': {
			...messageBubbleTailBase,
			borderBottomWidth: 18,
			borderLeftWidth: 11,
			borderRightWidth: 11,
			borderStyle: 'solid',
			borderTopWidth: 18,
		},
		'::before': {
			...messageBubbleTailBase,
			borderBottomWidth: 16,
			borderLeftWidth: 10,
			borderRightWidth: 10,
			borderStyle: 'solid',
			borderTopWidth: 16,
			zIndex: 1,
		},
		borderRadius: 10,
		maxWidth: 300,
		padding: 1,
		position: 'relative',
	},
});
