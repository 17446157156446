import * as SharedAppState from '@AppModels/AppState';
import * as Api from '@ViewModels';
import moment from 'moment';
import * as yup from 'yup';
import {
	IContentCalendarSelectedSuggestion,
	ICreateCampaignRequest,
	IKeyDateSelection,
	ILocationState,
	IRichContentDocumentEditorPlaceholder,
	InternalLevitateAccountIds,
	SocialMediaPostStatusDetails,
	TemplateOrTemplateFilter,
} from '.';
import { ImpersonationContextViewModel } from '../viewmodels/AdminViewModels';
import { ComposeEmailViewModel } from '../viewmodels/AppViewModels';
import { reactQueryClient } from '../web/ReactQueryProvider';
import FortyTwoFloorsIconUrl from '../web/assets/42floors.png';
import EverquoteUrl from '../web/assets/Everquote.png';
import IDXBrokerIconUrl from '../web/assets/IDX.png';
import AolLogoUrl from '../web/assets/aol_logo.png';
import CrexiIconUrl from '../web/assets/crexi.png';
import DatalotUrl from '../web/assets/datalot.png';
import elpUrl from '../web/assets/elp.png';
import GenericEmailLogoUrl from '../web/assets/email_graphic.svg';
import ExchangeLogoUrl from '../web/assets/exchange_logo.png';
import FacebookLogoUrl from '../web/assets/facebook_logo.png';
import FiveStreetIconUrl from '../web/assets/five_street.png';
import FollowuUpBossIconUrl from '../web/assets/followupboss.png';
import Forge3IconUrl from '../web/assets/forge3.png';
import GoogleLogoUrl from '../web/assets/google_logo_large.png';
import HomeLightIconUrl from '../web/assets/homelight.png';
import HomesComIconUrl from '../web/assets/homescom.svg';
import IHomeFinderIconUrl from '../web/assets/ihomefinder.jpg';
import IHouseWebIconUrl from '../web/assets/ihouseweb.png';
import InstagramLogoUrl from '../web/assets/instagram_logo.png';
import KeapIconUrl from '../web/assets/keap.svg';
import LassoCRMIconUrl from '../web/assets/lasso.png';
import LinkedInLogoUrl from '../web/assets/linkedIn_logo.png';
import NOLOIconUrl from '../web/assets/logo_nolo.svg';
import salesforceLogoImageUrl from '../web/assets/logo_salesforce.svg';
import xeroLogoUrl from '../web/assets/logo_xero_blue.png';
import quickbooksOnlineLogomarkImageUrl from '../web/assets/logomark_quickbooks_online.svg';
import LoopNetIconUrl from '../web/assets/loopnet.svg';
import O365LogoUrl from '../web/assets/office_365_logo.png';
import OpcityIconUrl from '../web/assets/opcity.png';
import PlacesterUrl from '../web/assets/placester.png';
import RealtorIconUrl from '../web/assets/realtor.png';
import ReferralExchangeIconUrl from '../web/assets/referralexchange.png';
import SmartVestorUrl from '../web/assets/smartvestor.jpg';
import SpacioIconUrl from '../web/assets/spacio.png';
import TrustedChoiceUrl from '../web/assets/trustedchoice.png';
import WizardCallsUrl from '../web/assets/wizardcalls.png';
import YahooLogoUrl from '../web/assets/yahoo_logo.png';
import ZBuyerIconUrl from '../web/assets/zbuyer.png';
import ZillowIconUrl from '../web/assets/zillow.png';
import { IConfirmationDialogOption } from '../web/components/ConfirmationDialog';
import { ISelectOption } from '../web/components/Select';
import { ISelectBoxOption } from '../web/components/SelectBox';
import { Countries } from '../web/components/ValueSelectors/CountrySelector';
import { CampaignType, ITemplatesIndustryWithCategories } from './AdminModels';
import { EventLogger } from './Logging';
import { IPixabayImage, IPixabayImageSearchOptions } from './Pixabay';
import {
	DefaultMeetingEmailPlaceholders,
	FirstNamePlaceholder,
	GetDefaultEmailPlaceholders,
	MeetingDatePlaceholder,
	MeetingDayPlaceholder,
	MeetingTimePlaceholder,
	MeetingTimeZonePlaceholder,
	SenderFirstNamePlaceholder,
	Token,
} from './Token';

export const DeleteConfirmationOptions: IConfirmationDialogOption[] = [
	{
		isDestructive: true,
		representedObject: true,
		title: 'Delete',
	},
	{
		isCancel: true,
		representedObject: false,
		title: 'Cancel',
	},
];

export const canViewContentCalendarAndCampaigns = (
	userSession: Api.UserSessionContext,
	impersonationContext?: Api.IImpersonationContext
) => {
	const planDetails = impersonationContext?.account?.planDetails ?? userSession?.account?.planDetails;
	// Default to not paid if planId is undefined or null. 1 is business and 1.5 is business plus
	return planDetails?.planId ?? 0 > 0;
};

export const canSendHtmlNewsletters = (userSession: Api.UserSessionContext) => {
	return (
		canViewContentCalendarAndCampaigns(userSession) &&
		userSession?.account?.features?.htmlNewsletter?.enabled &&
		userSession?.account?.features?.htmlNewsletter?.isConfigured
	);
};

/**
 * Calculates the start and end date of the visible calendar based on a given date in the calendar.
 * @param dateInCalendar - The date in the calendar to calculate the visible calendar range
 * @param options.numWeeks - Number of weeks shown in the calendar
 */
export const calcScheduleCampaignCalendarDateRange = ({
	selectedDate = new Date(),
	numWeeks = 4,
}: { selectedDate?: Date | moment.Moment; numWeeks?: number } = {}) => {
	const calendarStartDate = moment(selectedDate).startOf('month').startOf('week');
	const calendarEndDay = moment(calendarStartDate)
		.add(numWeeks - 1, 'weeks')
		.endOf('week');
	return { startDate: calendarStartDate.toDate(), endDate: calendarEndDay.toDate() };
};

export const debounce = (func: (...args: any[]) => void, wait: number, immediate?: boolean) => {
	let timeout: number;

	return function () {
		// eslint-disable-next-line prefer-rest-params
		const args = arguments;
		const later = () => {
			timeout = null;
			if (!immediate) {
				func(args);
			}
		};
		const callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = window.setTimeout(later, wait);
		if (callNow) {
			func(args);
		}
	};
};

export const getDisplayName = (principal: Api.IPrincipal, short = false) => {
	if (!!principal?.firstName && !!principal?.lastName) {
		return `${principal.firstName} ${short ? `${principal.lastName.charAt(0)}.` : principal.lastName}`;
	}

	if (principal?.primaryEmail?.value) {
		return principal.primaryEmail.value;
	}

	return '';
};

export const getPrincipalInitials = (principal: Api.IPrincipal): string => {
	return !!principal.firstName && !!principal.lastName
		? `${principal.firstName.substring(0, 1)}${principal.lastName.substring(0, 1)}`
		: !!principal.primaryEmail && !!principal.primaryEmail.value
			? principal.primaryEmail.value.substring(0, 1)
			: '';
};

export const getCompanyInitials = (company: Api.ICompany): string => {
	return (company?.companyName || '').split(' ').reduce((p, c, i) => (i < 2 ? `${p}${c.substring(0, 1)}` : p), '');
};

export const getContactCompanyLine = (contact: Api.IContact) => {
	return contact.jobTitle
		? `${contact.jobTitle}${contact.companyName ? ` at ${contact.companyName}` : ''}`
		: contact.companyName;
};

export const getDisplayNamePreferFirstNameOverEmail = (principal: Api.IPrincipal, short = false) => {
	if (principal?.firstName) {
		return `${principal.firstName} ${
			short && principal.lastName ? `${principal.lastName?.charAt(0)}.` : principal.lastName ?? ''
		}`;
	}

	if (principal?.primaryEmail?.value) {
		return principal.primaryEmail.value;
	}

	return '';
};

export const getDisplayNameWithEmailFirst = (principal: Api.IPrincipal, short = false) => {
	if (principal?.primaryEmail?.value) {
		return principal.primaryEmail.value;
	}

	return getDisplayName(principal, short);
};

export const getDisplayNameForBulkEmail = (principal: Api.IPrincipal, short = false) => {
	if (!!principal?.firstName && !principal?.lastName && !!principal?.primaryEmail?.value) {
		return `${principal.firstName} (${principal.primaryEmail.value})`;
	}

	return getDisplayName(principal, short);
};

export const getDisplayNameFirstNameOnly = (principal: Api.IPrincipal) => {
	if (principal?.firstName) {
		return principal.firstName;
	}

	if (principal?.primaryEmail) {
		return principal.primaryEmail.value;
	}

	return null;
};

const EntityKeys: (keyof Api.IUser | keyof Api.IContact | keyof Api.ICompany)[] = [
	'firstName',
	'lastName',
	'primaryEmail',
	'profilePic',
	'keyFactsCollection',
	'tags',
	'handle',
	'emailDomain',
	'emailDomains',
	'logoUrl',
];
export const isEntity = (entity: any) => {
	return !!entity && !!EntityKeys.find(x => Object.prototype.hasOwnProperty.call(entity, x));
};

export const getEntityDisplayName = (entity: Api.IEntity) => {
	if (entity) {
		return Object.prototype.hasOwnProperty.call(entity, 'firstName') ||
			Object.prototype.hasOwnProperty.call(entity, 'lastName') ||
			Object.prototype.hasOwnProperty.call(entity, 'email') ||
			Object.prototype.hasOwnProperty.call(entity, 'primaryEmail')
			? getDisplayName(entity as Api.IPrincipal)
			: entity.companyName;
	}
	return '';
};

export const replaceTokenWithActualFirstName = (
	email: Api.EmailMessageViewModel<File>,
	contact: Api.ContactViewModel
) => {
	if (contact.firstName) {
		try {
			const document = Token.replaceToken(email?.content?.document, FirstNamePlaceholder, contact.firstName);
			if (document) {
				email.content = {
					...email.content,
					document,
				};
			}
		} catch (error) {
			// swallow this (There was no token or it was invalid)
		}
	}

	return email;
};

export const firstNameHtml = new Token(FirstNamePlaceholder).toHTMLString();

export const defaultRichContentForEmailAutomationStep = Api.createRawRichTextContentStateWithText(
	`Hi ${firstNameHtml},`
);

export const getRichContentMessageForTextingStep = (
	userSession: Api.UserSessionContext,
	trigger: Api.IAutomationTrigger,
	schedule: Api.IAutomationStepSchedule
) => {
	const placeholders: IRichContentDocumentEditorPlaceholder[] = [
		...GetDefaultEmailPlaceholders(userSession),
		...DefaultMeetingEmailPlaceholders,
	];

	const senderFirstNameHtml = new Token(
		placeholders.find(x => x.text === SenderFirstNamePlaceholder.text)
	).toHTMLString();
	const accountCompanyName: string = userSession?.account.companyName;
	const meetingDateHtml = new Token(placeholders.find(x => x.text === MeetingDatePlaceholder.text)).toHTMLString();
	const meetingDayHtml = new Token(placeholders.find(x => x.text === MeetingDayPlaceholder.text)).toHTMLString();
	const meetingTimeHtml = new Token(placeholders.find(x => x.text === MeetingTimePlaceholder.text)).toHTMLString();
	const meetingTimeZoneHtml = new Token(
		placeholders.find(x => x.text === MeetingTimeZonePlaceholder.text)
	).toHTMLString();

	const greetings = `Hi ${firstNameHtml},`;

	const content: string =
		trigger?._type !== Api.AutomationTriggerType.Meeting || schedule?.numberOfDays > 0 || schedule?.numberOfHours > 0
			? greetings
			: `${greetings} this is ${senderFirstNameHtml} from ${accountCompanyName}. ${
					(schedule?.numberOfDays >= -1 && schedule?.numberOfDays !== 0) ||
					(schedule?.numberOfHours >= -24 && schedule?.numberOfHours !== 0)
						? `Just wanted to remind you that our meeting is ${meetingDayHtml}, ${meetingDateHtml} at ${meetingTimeHtml}(${meetingTimeZoneHtml}). Let me know if you need to make changes.`
						: `I am looking forward to our meeting on ${meetingDayHtml}, ${meetingDateHtml} at ${meetingTimeHtml}(${meetingTimeZoneHtml}). Please let me know if you need to reschedule. Thank you.`
				}`;
	return content;
};

export const replaceTokenWithActualFirstNameString = (rawContent: Api.IRawRichTextContentState, firstName: string) => {
	if (firstName) {
		try {
			const document = Token.replaceToken(rawContent?.document, FirstNamePlaceholder, firstName);
			if (document) {
				rawContent = {
					...rawContent,
					document,
				};
			}
		} catch (error) {
			// swallow this (There was no token or it was invalid)
		}
	}

	return rawContent;
};

export const getNormalizedEmailProvider = (emailProvider?: Api.EmailProviderType): Api.EmailProviderType => {
	if (emailProvider == null) {
		return null;
	}

	switch (emailProvider) {
		case 'Google':
		case 'Office365':
		case 'Gmail':
		case 'Exchange':
		case 'Imap':
		case 'Misc':
		case 'Yahoo':
		case 'Aol':
		default:
			return emailProvider;
	}
};

export const getSocialMediaPlatformLogoUrl = (platform?: Api.SocialMediaType) => {
	let platformLogoUrl = '';

	switch (platform) {
		case Api.SocialMediaType.Facebook:
			platformLogoUrl = FacebookLogoUrl;
			break;
		case Api.SocialMediaType.LinkedIn:
			platformLogoUrl = LinkedInLogoUrl;
			break;
		case Api.SocialMediaType.Instagram:
			platformLogoUrl = InstagramLogoUrl;
			break;
		default:
			break;
	}

	return platformLogoUrl;
};

export const getEmailProviderLogoUrl = (emailProvider?: Api.EmailProviderType) => {
	const normalizedEmailProvider = getNormalizedEmailProvider(emailProvider);
	if (!normalizedEmailProvider) {
		return null;
	}

	if (normalizedEmailProvider === 'Google' || normalizedEmailProvider === 'Gmail') {
		return GoogleLogoUrl;
	} else if (normalizedEmailProvider === 'Office365' || normalizedEmailProvider === 'Outlook') {
		return O365LogoUrl;
	} else if (normalizedEmailProvider === 'Exchange') {
		return ExchangeLogoUrl;
	} else if (normalizedEmailProvider === 'Yahoo') {
		return YahooLogoUrl;
	} else if (normalizedEmailProvider === 'Aol') {
		return AolLogoUrl;
	}

	return GenericEmailLogoUrl;
};

export const getEmailProviderDisplayName = (emailProvider?: Api.EmailProviderType) => {
	const normalizedEmailProvider = getNormalizedEmailProvider(emailProvider);
	if (normalizedEmailProvider == null) {
		return '';
	}

	let emailProviderName = '';
	switch (normalizedEmailProvider) {
		case 'Google':
			emailProviderName = 'Google Workspace';
			break;
		case 'Office365':
			emailProviderName = 'Office 365';
			break;
		case 'Gmail':
			emailProviderName = 'Gmail';
			break;
		case 'Exchange':
			emailProviderName = 'Exchange Server';
			break;
		case 'Imap':
			emailProviderName = 'email';
			break;
		case 'Yahoo':
			emailProviderName = 'Yahoo';
			break;
		case 'Aol':
			emailProviderName = 'AOL';
			break;
		case 'Misc':
		default:
			emailProviderName = '';
			break;
	}

	return emailProviderName;
};

export type NormalizedNoteVisibility = 'Private' | 'Shared' | 'Not available' | 'Group';

export const getNormalizedNoteVisibility = (visibility: string): NormalizedNoteVisibility => {
	const noteVisibility = visibility || 'none';
	switch (noteVisibility) {
		case 'admin':
			return 'Private';
		case 'all':
			return 'Shared';
		case 'none':
			return 'Not available';
		default:
			return 'Group';
	}
};

export const keepInTouchFrequencyStringValue = (frequency: number, capitalize = false) => {
	if (frequency < 0) {
		return `${capitalize ? 'N' : 'n'}o ${capitalize ? 'S' : 's'}chedule`;
	}

	if (frequency === 12) {
		return `${capitalize ? 'Y' : 'y'}ear`;
	}

	return `${frequency > 1 ? frequency : ''} ${capitalize ? 'M' : 'm'}onth${frequency > 1 ? 's' : ''}`;
};

export const numberToCurrencyStringValue = (value: number) => {
	return value.toLocaleString('en', {
		maximumFractionDigits: 2,
		minimumFractionDigits: 0,
	}); // e.g 0.00
};

export const numberToUSDCurrencyStringValue = (value: number) => {
	return `${value.toLocaleString('en', {
		maximumFractionDigits: 2,
		minimumFractionDigits: 2,
		style: 'currency',
		currency: 'USD',
	})}`; // e.g 0.00
};

export const numberToFormattedCount = (value: number) => {
	return value.toLocaleString('en'); // e.g 1,000
};

export const sanitizeCurrencyStringValue = (inputString: string) => {
	const stringValueNumbers = (inputString || '').replace(/[^\d.-]/g, '');
	try {
		const asNumber = parseFloat(stringValueNumbers ? stringValueNumbers : '0');
		return asNumber.toFixed(2); // e.g 0.00
	} catch (_) {
		return null;
	}
};

/**
 * Helper method for setting property values of an object. Useful if you need to set a private/protected value that you
 * know exists.
 *
 * @param object Object to work on
 * @param propertyName Name of property to modify
 * @param value New value to set
 * @param setOnlyIfObjectHasProperty Flag controlling safty check (default = true)
 */
export const setObjectPropertyValue = (
	object: any,
	propertyName: string,
	value: any,
	setOnlyIfObjectHasProperty = true
) => {
	if (!!object && !!propertyName) {
		if (!!setOnlyIfObjectHasProperty && !Object.prototype.hasOwnProperty.call(object, propertyName)) {
			return;
		}
		object[propertyName] = value;
	}
};

export const getFileSizeStringValue = (numberOfBytes: number) => {
	if (numberOfBytes < 1024) {
		return `${numberOfBytes} bytes`;
	} else if (numberOfBytes >= 1024 && numberOfBytes < 1048576) {
		return `${(numberOfBytes / 1024).toFixed(1)} KB`;
	} else if (numberOfBytes >= 1048576 && numberOfBytes < 1073741824) {
		return `${(numberOfBytes / 1048576).toFixed(1)} MB`;
	}

	return `${(numberOfBytes / 1073741824).toFixed(1)} GB`;
};

export const SimpleEmailValidationRegExp = new RegExp('^.+@.+\\..+$', 'i');

export const unsupportedISPs = ['bellsouth.net', 'sbcglobal.net'];

export const isSupportedISP = (isp: string) => {
	if (unsupportedISPs.includes(isp)) {
		return false;
	}

	return true;
};

/**
 * Really simple email validation. This is intentionally not robust.
 *
 * @param email Email to test
 */
export const isValidEmail = (email?: string) => {
	if (email) {
		return SimpleEmailValidationRegExp.test(email);
	}

	return false;
};

export const cleanPhone = (phoneNumber: string) => {
	return phoneNumber.replace(/\D/g, '');
};

// Simple phone validation checking presence is not opted-in to text, and some additional validation if opted-in
export const isValidPhone = (phoneNumber: string, textOptIn?: boolean) => {
	const numbersOnlyString = cleanPhone(phoneNumber);

	if (isNaN(parseInt(numbersOnlyString, 10))) {
		return false;
	}
	if (!textOptIn) {
		return true;
	}
	const firstDigitIsOne = numbersOnlyString[0] === '1';

	if (numbersOnlyString.length === 10) {
		return true;
	} else if (firstDigitIsOne && numbersOnlyString.length === 11) {
		return true;
	}
	return false;
};

export const getEntityViewModelsFromLocationState = <TViewModel = any, TModel = any>(
	locationState?: ILocationState<TViewModel, TModel>
) => {
	let entityViewModels: Api.EntityViewModel<Api.IEntity>[] = null;

	if (!!locationState && !!locationState.viewModel) {
		if (locationState.viewModel instanceof Api.OpportunityViewModel) {
			const companyModel = locationState.viewModel.company ? locationState.viewModel.company : null;
			const primaryContactModel = locationState.viewModel.primaryContact
				? locationState.viewModel.primaryContact
				: null;
			if (!!primaryContactModel || !!companyModel) {
				entityViewModels = [primaryContactModel, companyModel].filter(x => !!x);
			}
		} else if (locationState.viewModel instanceof Api.EntityViewModel) {
			entityViewModels = [locationState.viewModel];
		}
	}

	return entityViewModels;
};

/**
 * Given location state, get a list of entities for use in composing rich content (notes, action items, email messages,
 * etc)
 *
 * @param locationState Location state model
 */

export const getMentionEntitiesFromLocationState = <TViewModel = any, TModel extends Api.IEntity = Api.IEntity>(
	locationState?: ILocationState<TViewModel, TModel>
): Api.IEntity[] => {
	const entityViewModels = getEntityViewModelsFromLocationState(locationState);

	if (entityViewModels) {
		return entityViewModels.map(x => x.toMention());
	} else {
		if (!!locationState && !!locationState.model && !!isEntity(locationState.model)) {
			return [locationState.model];
		}
	}

	return null;
};

export const searchRequestToQueryStringParams = <T = any>(
	searchRequest: T,
	sortDescriptor?: Api.ISortDescriptor
): Api.IDictionary<string> | null => {
	const params: Api.IDictionary<string> = {};
	try {
		if (searchRequest) {
			params.search = encodeURIComponent(btoa(encodeURIComponent(JSON.stringify(searchRequest))));
		}

		// add sort options
		if (sortDescriptor) {
			params.sort = encodeURIComponent(btoa(encodeURIComponent(JSON.stringify(sortDescriptor))));
		}
	} catch (_) {
		// parse error
		return null;
	}

	return params;
};

export const queryStringParamsToSearchRequest = <T = any>(params: Api.IDictionary<string>) => {
	const result: { searchRequest: T; sortDescriptor: Api.ISortDescriptor } = {
		searchRequest: {} as any,
		sortDescriptor: {},
	};

	try {
		if (params.search) {
			result.searchRequest = JSON.parse(decodeURIComponent(atob(decodeURIComponent(params.search))));
		}

		if (params.sort) {
			result.sortDescriptor = JSON.parse(decodeURIComponent(atob(decodeURIComponent(params.sort))));
		}

		if (Object.keys(result.searchRequest).length === 0) {
			delete result.searchRequest;
		}

		if (Object.keys(result.sortDescriptor).length === 0) {
			delete result.sortDescriptor;
		}
	} catch (_) {
		// parse error
		return null;
	}

	return Object.keys(result).length > 0 ? result : null;
};

export const remindersToKeepInTouchWithContacts = (reminders: Api.ActionItemViewModel[]) => {
	const results: {
		kitActionItems: Api.ActionItemViewModel[];
		contacts: Api.ContactViewModel[];
	} = {
		contacts: [],
		kitActionItems: [],
	};

	results.kitActionItems = (reminders || []).filter(x => {
		// include kit action items that aren't suggested, and/or suggested kits that have active tag alerts
		return (
			(!!x.isKeepInTouchActionItem && !x.isSuggestedKeepInTouchActionItem) ||
			(!!x.isSuggestedKeepInTouchActionItem &&
				!!x.keepInTouchReference.contact.tagAlerts &&
				x.keepInTouchReference.contact.tagAlerts.length > 0)
		);
	});

	results.contacts = (results.kitActionItems || []).reduce<Api.ContactViewModel[]>((result, actionItem) => {
		result.push(actionItem.keepInTouchReference.contact);
		return result;
	}, []);

	return results;
};

export const createContentStateWithHtmlStringValue = (contentHtmlStringValue: string, onResetCallback?: () => void) => {
	const rawContentState: Api.IRawRichTextContentState = {
		document: `<${Api.RawRichTextContentDocumentRootElementName}>${contentHtmlStringValue || ''}</${
			Api.RawRichTextContentDocumentRootElementName
		}>`,
		documentVersion: 1,
	};
	const content: Api.IRichContentEditorState = {
		getPlainTextPreview: () => {
			return '';
		},
		getRawRichTextContent: () => {
			return rawContentState;
		},
		hasContent: () => {
			return !!contentHtmlStringValue;
		},
		reset: onResetCallback || Api.VmUtils.Noop,
	};
	return content;
};

export const replaceFontFamilyInCss = (fontFamily: string, targetCssStringValue: string) => {
	return (targetCssStringValue || '').replace(/font-family:[^;]+;/i, `font-family:${fontFamily},sans-serif;`);
};

export const createRichContentEditorStateBlock = (
	innerHtml?: string,
	defaultRawRichTextContentBlockStyleAttribute?: string
) => {
	return `<p style="${
		defaultRawRichTextContentBlockStyleAttribute || Api.DefaultRawRichTextContentBlockStyleAttribute
	}">${innerHtml || ''}</p>`;
};

export const createRichContentEditorStateWithText = (
	text?: string,
	defaultRawRichTextContentBlockStyleAttribute?: string,
	onResetCallback?: () => void
) => {
	const htmlStringValue = (text || '').split(/[\n|\r|\n\r]+/).reduce((prev, x) => {
		return `${prev}${createRichContentEditorStateBlock(x, defaultRawRichTextContentBlockStyleAttribute)}`;
	}, '');
	const rawFormat: Api.IRawRichTextContentState = {
		document: `<${Api.RawRichTextContentDocumentRootElementName}>${htmlStringValue}</${Api.RawRichTextContentDocumentRootElementName}>`,
		documentVersion: 1,
	};
	const result: Api.IRichContentEditorState = {
		getPlainTextPreview: (truncate = true, terminator?: string) => {
			if (!truncate) {
				return `${text || ''}${!!text && !!terminator ? terminator : ''}`;
			}
			return `${(text || '').substring(0, 100)}${!!text && !!terminator ? terminator : ''}`;
		},
		getRawRichTextContent: () => {
			return rawFormat;
		},
		hasContent: () => {
			return !!text;
		},
		reset: onResetCallback || Api.VmUtils.Noop,
	};
	return result;
};

export const getDefaultBulkMessagingBodyEditorState = () => {
	const token = new Token(FirstNamePlaceholder);
	return createContentStateWithHtmlStringValue(`Hi ${token.toHTMLString()},`);
};

export const createEmtpyRawRichTextContentState = (onResetCallback?: () => void) => {
	return createContentStateWithHtmlStringValue('', onResetCallback);
};

/**
 * Converts IRawRichTextContentState to IRichContentEditorState using `IRawRichTextContentState.content.document` as
 * input
 *
 * @param rawContentState
 */
export const convertRawRichTextContentStateToRichContentEditorState = (
	rawContentState?: Api.IRawRichTextContentState,
	plainTextContent = '',
	onResetCallback?: () => void
) => {
	const rawContentStateWithoutDraftContent = rawContentState
		? { ...rawContentState }
		: {
				document: `<${Api.RawRichTextContentDocumentRootElementName}></${Api.RawRichTextContentDocumentRootElementName}>`,
				documentVersion: 1,
			};
	if (rawContentStateWithoutDraftContent) {
		// remove legacy keys
		delete (rawContentStateWithoutDraftContent as any).blocks;
		delete (rawContentStateWithoutDraftContent as any).entityMap;
	}

	const hasContent =
		!!rawContentStateWithoutDraftContent && !!rawContentStateWithoutDraftContent.document
			? !!Api.getContentHtmlStringValueFromRawRichTextContentState(rawContentState)
			: false;

	const text =
		plainTextContent ||
		(hasContent ? convertHtmlStringValueToPlainText(rawContentStateWithoutDraftContent.document) : '');

	const result: Api.IRichContentEditorState = {
		getPlainTextPreview: (truncate = true, terminator?: string) => {
			if (!truncate) {
				return `${text || ''}${!!text && !!terminator ? terminator : ''}`;
			}
			return `${(text || '').substring(0, 100)}${!!text && !!terminator ? terminator : ''}`;
		},
		getRawRichTextContent: () => {
			return rawContentStateWithoutDraftContent;
		},
		hasContent: () => {
			return hasContent;
		},
		reset: onResetCallback || Api.VmUtils.Noop,
	};
	return result;
};

// Define types for input and output schemas
type SchemaType<T extends yup.AnyObject> = yup.ObjectSchema<T>;
type WrappedSchema<T> = yup.ObjectSchema<{ pageToken?: string; totalCount: number; values: T[] }>;

// Function to wrap a Yup schema with a page collection schema
export function wrapWithPageCollectionSchema<T extends yup.AnyObject>(inputSchema: SchemaType<T>): WrappedSchema<T> {
	return yup
		.object()
		.shape({
			pageToken: yup.string().optional(),
			totalCount: yup.number().required(),
		})
		.concat(
			yup.object().shape({
				values: yup.array().of(inputSchema).optional(),
			})
		) as WrappedSchema<T>;
}

export async function yupSafeValidate<T extends yup.Schema>(schema: T, value: any) {
	try {
		return await schema.validate(value);
	} catch (error) {
		if (process.env.NODE_ENV === 'production') {
			return value as T['__outputType'];
		}
		throw error;
	}
}

export const tagAlertDurationStringValue = (tagAlert: Api.TagAlertViewModel) => {
	if (!tagAlert) {
		return '';
	}

	let calendarUnit = 0;
	let calendarUnitLabel = '';

	if (tagAlert.interactionInterval % 30 === 0) {
		calendarUnit = tagAlert.interactionInterval / 30;
		calendarUnitLabel = 'month';
	} else if (tagAlert.interactionInterval === 365) {
		calendarUnit = 12;
		calendarUnitLabel = 'month';
	} else if (tagAlert.interactionInterval % 7 === 0) {
		calendarUnit = tagAlert.interactionInterval / 7;
		calendarUnitLabel = 'week';
	} else {
		calendarUnit = tagAlert.interactionInterval;
		calendarUnitLabel = 'day';
	}

	if (calendarUnit > 1) {
		calendarUnitLabel += 's';
	}

	return `${calendarUnit} ${calendarUnitLabel}`;
};

export const getContactTitleAndCompany = (contact: Api.IContact | Api.ContactViewModel) => {
	if (contact) {
		if (!!contact.jobTitle && !!contact.companyName) {
			return `${contact.jobTitle} at ${contact.companyName}`;
		}

		if (!!contact.jobTitle && !contact.companyName) {
			return contact.jobTitle;
		}

		if (!!contact.companyName && !contact.jobTitle) {
			return contact.companyName;
		}
	}
	return '';
};

export const truncateTextToLength = (text: string, length: number, maxLineCount = 1000) => {
	let truncatedText = text || '';
	const lines = truncatedText
		.split('\n')
		.filter(x => !!x)
		.slice(0, maxLineCount);
	truncatedText = lines.join('\n');
	const len = Math.max(0, length);
	if (truncatedText.length > len) {
		truncatedText = truncatedText.substring(0, len);
		const lastWhiteSpaceIndex = truncatedText.search(/[\s ]$/);
		if (lastWhiteSpaceIndex >= 0) {
			truncatedText = truncatedText.substring(0, lastWhiteSpaceIndex);
		}
	}
	return truncatedText.trim();
};

export const convertHtmlStringValueToPlainText = (htmlStringValue: string) => {
	const el = document.createElement('div');
	el.innerHTML = htmlStringValue;
	const text = el.textContent || '';
	if (el.parentNode) {
		el.parentNode.removeChild(el);
	}
	return text;
};

export const getSocialMediaPlainTextContentFromRawRichContent = (rawRichContent: Api.IRawRichTextContentState) => {
	const htmlStringValue = Api.getContentHtmlStringValueFromRawRichTextContentState(rawRichContent);
	const el = document.createElement('div');
	el.innerHTML = htmlStringValue;

	let result = '';
	if (el.firstElementChild) {
		for (let i = 0; i < el.children.length; i++) {
			const child = el.children.item(i);
			const text = child.textContent;
			result =
				result + (!text || text.toLocaleLowerCase() === ';nbsp' ? '' : text) + (i + 1 < el.children.length ? '\n' : '');
		}
	} else if (!el.textContent || el.textContent?.toLocaleLowerCase() === ';nbsp') {
		result = '';
	} else {
		result = el.textContent;
	}

	if (el.parentNode) {
		el.parentNode.removeChild(el);
	}
	return result;
};

export const stringsEqual = (
	firstString?: string,
	otherString?: string,
	options?: { caseInsensitiveCompare?: boolean }
) => {
	if (!!firstString && !!otherString) {
		if (!!options && !!options.caseInsensitiveCompare) {
			const lowerThis = firstString.toLocaleLowerCase();
			const lowerOtherString = otherString.toLocaleLowerCase();
			return lowerThis === lowerOtherString;
		}
	}

	if (firstString === otherString) {
		return true;
	}

	return false;
};

export const getDiscountName = (discount: Api.AccountDiscountPercent) => {
	switch (discount) {
		case Api.AccountDiscountPercent.Ten:
			return '10%';
		case Api.AccountDiscountPercent.Fifteen:
			return '15%';
		case Api.AccountDiscountPercent.TwentyFive:
			return '25%';
		default:
			return '';
	}
};

export const getShortenedString = (str: string, maxLen: number, separator = ' ') => {
	if (str?.length <= maxLen && !!str) {
		return str;
	}
	return str?.substring(0, str.lastIndexOf(separator, maxLen));
};

export const getDefaultDateStringValue = (date: moment.MomentInput) => {
	return moment(date).format('MM/DD/YYYY');
};

export const getDefaultTimeStringValue = (date: moment.MomentInput) => {
	return moment(date).format('h:mma');
};

export const getLastPathComponent = (path: string) => {
	const pathComps = (path ? path : '').split('/').filter(x => !!x);
	const lastPathComp = pathComps.length > 0 ? pathComps[pathComps.length - 1] : '';
	return lastPathComp;
};

export const replaceLastPathComponent = (path: string, replacement?: string) => {
	const pathComps = (path || '').split('/').filter(x => !!x);
	pathComps.splice(pathComps.length - 1, 1, replacement ? replacement : undefined);
	const result = pathComps.join('/');
	return result.charAt(0) === '/' ? result : `/${result}`;
};

export const openDownloadUrl = (url: string, fileName: string) => {
	if (url) {
		const link = document.createElement('a');
		link.setAttribute('style', 'display:none;opacity=0;position=absolute;visibility=collapse;');
		link.href = url;
		link.download = fileName;
		link.target = '_blank';
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}
};

export const openUrlInNewTab = (url: string) => {
	if (url) {
		const link = document.createElement('a');
		link.setAttribute('style', 'display:none;opacity=0;position=absolute;visibility=collapse;');
		link.href = url;
		link.target = '_blank';
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}
};

export const copyToClipboard = (value: string) => {
	try {
		const textarea = document.createElement('textarea');
		textarea.setAttribute('style', 'opacity=0;position=absolute;visibility=collapse;z-index:-1;');
		textarea.value = value;
		document.body.appendChild(textarea);
		textarea.select();
		document.execCommand('copy');
		document.body.removeChild(textarea);
		return true;
	} catch (e) {
		EventLogger.logEvent(
			{
				action: 'CopyToClipboard',
				category: 'UiUtils',
			},
			{ exception: Api.asApiError(e) }
		);
		return false;
	}
};

export const isTagSearchContactFilterCriteria = (criteria: Api.IContactFilterCriteria): boolean => {
	if (criteria) {
		if (!!criteria.criteria && criteria.criteria.length > 0) {
			return !!criteria.criteria.every(x => !!isTagSearchContactFilterCriteria(x));
		} else if (criteria.property === Api.ContactFilterCriteriaProperty.Tag) {
			return true;
		}
	}

	return false;
};

export const contactFilterCriteriaHasValue = (
	criteria: Api.IContactFilterCriteria,
	value: string,
	options?: { caseInsensitiveCompare?: boolean }
): boolean => {
	if (criteria) {
		const caseInsensitiveCompare = (!!options && !!options.caseInsensitiveCompare) || false;
		const mValue = caseInsensitiveCompare ? (value || '').toLocaleLowerCase() : value || '';
		if (!!criteria.criteria && criteria.criteria.length > 0) {
			return !!criteria.criteria.some(x => !!contactFilterCriteriaHasValue(x, value, options));
		}

		return (caseInsensitiveCompare ? (criteria.value || '').toLocaleLowerCase() : criteria.value || '') === mValue;
	}

	return false;
};

export const contactFilterCriteriaComponentCount = (criteria: Api.IContactFilterCriteria): number => {
	if (criteria) {
		if (!!criteria.criteria && criteria.criteria.length > 0) {
			return criteria.criteria.reduce((count, x) => (count += contactFilterCriteriaComponentCount(x)), 0);
		} else if (criteria.property) {
			return 1;
		}
	}

	return 0;
};

/** Flattens the IContactFilterCriteria to a list containing just policy filters */
export const getPolicyFilterCriteriaInCriteria = (
	criteria?: Api.IContactFilterCriteria
): Api.IContactFilterCriteria[] => {
	if (criteria?.criteria?.length) {
		return criteria.criteria.reduce((res, curr) => {
			return res.concat(getPolicyFilterCriteriaInCriteria(curr));
		}, []);
	}

	if (criteria?.property === Api.ContactFilterCriteriaProperty.Policy && !!criteria.value) {
		return [criteria];
	}
	return [];
};

export const dateIsToday = (date?: moment.MomentInput) =>
	date ? moment().utc(false).startOf('day').isSameOrBefore(date, 'day') : false;

export const emailScannerImageUrl = (id: Api.EmailScannerId) => {
	switch (id.toLocaleLowerCase()) {
		case Api.EmailScannerId.Crexi.toLocaleLowerCase():
			return CrexiIconUrl;
		case Api.EmailScannerId.FiveStreet.toLocaleLowerCase():
			return FiveStreetIconUrl;
		case Api.EmailScannerId.RealtorDotCom.toLocaleLowerCase():
			return RealtorIconUrl;
		case Api.EmailScannerId.Zillow.toLocaleLowerCase():
			return ZillowIconUrl;
		case Api.EmailScannerId.IHomefinder.toLocaleLowerCase():
			return IHomeFinderIconUrl;
		case Api.EmailScannerId.IHouseWeb.toLocaleLowerCase():
			return IHouseWebIconUrl;
		case Api.EmailScannerId.Spacio.toLocaleLowerCase():
			return SpacioIconUrl;
		case Api.EmailScannerId.ReferralExchange.toLocaleLowerCase():
			return ReferralExchangeIconUrl;
		case Api.EmailScannerId.Opcity.toLocaleLowerCase():
			return OpcityIconUrl;
		case Api.EmailScannerId.LoopNet.toLocaleLowerCase():
			return LoopNetIconUrl;
		case Api.EmailScannerId.ZBuyer.toLocaleLowerCase():
			return ZBuyerIconUrl;
		case Api.EmailScannerId.FollowUpBoss.toLocaleLowerCase():
			return FollowuUpBossIconUrl;
		case Api.EmailScannerId.HomesConnect.toLocaleLowerCase():
			return HomesComIconUrl;
		case Api.EmailScannerId.LassoCRM.toLocaleLowerCase():
			return LassoCRMIconUrl;
		case Api.EmailScannerId.Keap.toLocaleLowerCase():
			return KeapIconUrl;
		case Api.EmailScannerId.FortyTwoStores.toLocaleLowerCase():
			return FortyTwoFloorsIconUrl;
		case Api.EmailScannerId.HomeLight.toLocaleLowerCase():
			return HomeLightIconUrl;
		case Api.EmailScannerId.IDXBroker.toLocaleLowerCase():
			return IDXBrokerIconUrl;
		case Api.EmailScannerId.EndorsedLocalProviders.toLocaleLowerCase():
			return elpUrl;
		case Api.EmailScannerId.TrustedChoice.toLocaleLowerCase():
			return TrustedChoiceUrl;
		case Api.EmailScannerId.WizardCalls.toLocaleLowerCase():
			return WizardCallsUrl;
		case Api.EmailScannerId.Everquote.toLocaleLowerCase():
			return EverquoteUrl;
		case Api.EmailScannerId.Datalot.toLocaleLowerCase():
			return DatalotUrl;
		case Api.EmailScannerId.SmartVestor.toLocaleLowerCase():
			return SmartVestorUrl;
		case Api.EmailScannerId.Placester.toLocaleLowerCase():
			return PlacesterUrl;
		case Api.EmailScannerId.NOLO.toLocaleLowerCase():
			return NOLOIconUrl;
		case Api.EmailScannerId.Forge3.toLocaleLowerCase():
			return Forge3IconUrl;
		default:
			break;
	}
	return null;
};

export const filterList = (items: any[], condition: (x: any) => boolean) => {
	const matched: string[] = [];
	const unmatched: string[] = [];
	items.forEach(x => {
		const conditionTrue = condition(x);
		if (conditionTrue) {
			matched.push(x);
		} else {
			unmatched.push(x);
		}
	});
	return { matched, unmatched };
};

export const getFriendlyTimeIntervalSinceNow = (date: moment.MomentInput, includeTimeOfDay = false) => {
	if (!date) {
		return null;
	}

	const now = moment();
	const then = moment(date);
	const minutes = now.diff(then, 'minutes');
	if (minutes < 0) {
		return null;
	}

	if (minutes < 5) {
		return 'Just now';
	}

	if (minutes < 60) {
		return `${minutes} minutes ago`;
	}

	const timeOfDay = `${includeTimeOfDay ? ` at ${then.format('LT')}` : ''}`;

	if (now.startOf('day').isBefore(then)) {
		return `Today${timeOfDay}`;
	}

	if (now.startOf('day').subtract(1, 'day').isBefore(then)) {
		return `Yesterday${timeOfDay}`;
	}

	return `${then.format('l')}${timeOfDay}`;
};

export const polyfillAsync = async () => {
	if (!window?.ResizeObserver) {
		const ResizeObserverPolyfill = await import(
			/* webpackChunkName: "ResizeObserverPolyfill" */ '@juggle/resize-observer'
		);
		if (ResizeObserverPolyfill) {
			window.ResizeObserver = (ResizeObserverPolyfill as any).ResizeObserver;
		}
	}
};

/** Human readable name from Api.Industry */
export const getIndustryName = (industry: Api.Industry | 'All') => {
	return industry.replace(/_/gi, '');
};

export const getIndustries = ({ accountId }: { accountId?: string } = {}) => {
	let industries = [
		Api.Industry.Insurance,
		Api.Industry.RealEstate,
		Api.Industry.Financial,
		Api.Industry.Mortgage,
		Api.Industry.Legal,
		Api.Industry.Accounting,
		Api.Industry.NonProfit,
		Api.Industry.HomeMaintenance,
		Api.Industry.Others,
	];
	if (accountId && InternalLevitateAccountIds.has(accountId)) {
		industries = [Api.Industry.Levitate, ...industries];
	}
	return industries;
};

/** Calculate if template is new based on standard conditions */
export const isTemplateNew = (template: Api.ITemplate) => {
	if (!template?.creationDate) {
		return false;
	}

	return (
		moment().diff(moment(template?.creationDate), 'days') <= 30 &&
		// Don't show as 'New' if they created it themselves or scope is unknown
		(template?.scope === Api.TemplateScope.Industry || template?.scope === Api.TemplateScope.Account)
	);
};

const minutesToHours = (min: number) => {
	return Math.floor(min / 60);
};

export const getScheduleTimeOptions = (
	minutes: number,
	userSession?: Api.UserSessionContext,
	sendEmailFeatures?: Partial<Api.ISendEmailFeatures>,
	selectedMoment?: moment.Moment
) => {
	let interval: Api.IInterval = null;
	const sendEmail = sendEmailFeatures || userSession?.account?.features?.sendEmail;

	const checkMoment = moment(selectedMoment) || moment();

	if (!sendEmail?.observeSendIntervals) {
		interval = { endMinutes: 1439, startMinutes: 0 };
	} else {
		// isoWeekday returns 1-7 where 1 is Monday and 7 is Sunday
		switch (checkMoment.isoWeekday()) {
			case 1:
			case 2:
			case 3:
			case 4:
				interval = sendEmail?.weekdaysInterval;
				break;
			case 5:
				interval = sendEmail?.fridayInterval;
				break;
			case 6:
				interval = sendEmail?.saturdayInterval;
				break;
			case 7:
				interval = sendEmail?.sundayInterval;
				break;
			default:
				break;
		}
	}

	const ret: ISelectBoxOption<number>[] = [];
	if (interval) {
		// only replace if null or undefined - leave 0 intact
		interval.startMinutes = interval.startMinutes ?? 0;
		interval.endMinutes = interval.endMinutes ?? 1439;
		const min = minutesToHours(interval.startMinutes);
		// - 60 because if we are sending on the :05 minute, we don't want to allow
		// the user to send after that. If business hours end at 6:00, the last time
		// we should allow would be 5:05
		const max = minutesToHours(interval.endMinutes - 60);
		const selectedDate = new Date();

		for (let x = min; x <= max; x++) {
			selectedDate.setHours(x, minutes, 0);
			const title = getDefaultTimeStringValue(selectedDate);
			ret.push({
				isDefault: false,
				title,
				value: x,
			});
		}
	}

	return ret;
};

export const scheduleSendOverlapsWithExistingWorkload = (
	estimate?: Partial<Api.IEmailSendEstimate>,
	selectedDate?: Date,
	numberOfEmailsToSend?: number
) => {
	return !!selectedDate && !!estimate && !!estimate.emailWorkload
		? !!estimate.emailWorkload.forecast.find(x => {
				const sendStartMoment = moment(estimate.estimate.startDate).startOf('day');
				const sendEndMoment = moment(estimate.estimate.endDate).endOf('day');
				const numberOfDays = sendEndMoment.diff(sendStartMoment, 'days');
				const dateOverlaps = moment(x.date).startOf('day').isBetween(sendStartMoment, sendEndMoment, null, '[]');
				if (dateOverlaps) {
					if (numberOfDays > 0) {
						// send spans more than one 24hr period
						return true;
					}
					const dailyLimit =
						(estimate.emailWorkload.limits.find(limit => limit.intervalInMinutes === 1440) || {}).count || 0;
					return dailyLimit - x.count - (numberOfEmailsToSend || 1) < 0;
				}
				return false;
			})
		: false;
};

export const accountRequiresCompliance = (
	userSession: Api.UserSessionContext,
	impersonationCtx?: ImpersonationContextViewModel
) => {
	return impersonationCtx?.isValid
		? impersonationCtx.account?.preferences?.complianceSettings?.enabled
		: userSession?.account?.preferences?.complianceSettings?.enabled;
};

export const getComplianceDisabledDays = (
	user: Api.UserSessionContext,
	impersonationCtx: ImpersonationContextViewModel,
	ignoreCompliance: boolean
) => {
	const complianceWaitTime = impersonationCtx?.isValid
		? impersonationCtx?.account?.preferences?.complianceSettings?.waitTime
		: user?.account?.preferences?.complianceSettings?.waitTime;
	const mins = accountRequiresCompliance(user, impersonationCtx) && !ignoreCompliance ? complianceWaitTime ?? 2880 : 0;
	return moment().add(mins, 'minutes').toDate();
};

export const getComplianceWaitTimeString = (
	user: Api.UserSessionContext,
	impersonationCtx: ImpersonationContextViewModel
) => {
	const days = !impersonationCtx?.isValid
		? convertDurationFromString(user?.account?.preferences?.complianceSettings?.waitTime).days
		: convertDurationFromString(impersonationCtx?.account?.preferences?.complianceSettings?.waitTime).days;
	switch (days) {
		case 1: {
			return '1 day';
		}
		case 2: {
			return '2 days';
		}
		default: {
			return '1 hour';
		}
	}
};

export const getSendOnBehalfWaitTimeString = (
	user: Api.UserSessionContext,
	impersonationCtx: ImpersonationContextViewModel
) => {
	const days = !impersonationCtx?.isValid
		? convertDurationFromString(user?.account?.preferences?.sendOnBehalfWaitTime).days
		: convertDurationFromString(impersonationCtx?.account?.preferences?.sendOnBehalfWaitTime).days;
	let stringTime = '1 hour';
	if (days) {
		stringTime = `${days} day${days > 1 ? 's' : ''}`;
	}
	return stringTime;
};

export const getSendOnBehalfWaitTime = (
	user: Api.UserSessionContext,
	impersonationCtx?: ImpersonationContextViewModel
) => {
	const mins = !impersonationCtx?.isValid
		? user?.account?.preferences?.sendOnBehalfWaitTime
		: impersonationCtx?.account?.preferences?.sendOnBehalfWaitTime;
	return moment().add(mins, 'm');
};

export const getSendOnBehalfDisabledDays = (
	user: Api.UserSessionContext,
	impersonationCtx?: ImpersonationContextViewModel
) => {
	const DEFAULT_DAYS = 2880;
	const mins = !impersonationCtx?.isValid
		? user?.account?.preferences?.sendOnBehalfWaitTime ?? DEFAULT_DAYS
		: impersonationCtx?.account?.preferences?.sendOnBehalfWaitTime ?? DEFAULT_DAYS;
	return moment().add(mins, 'minutes').toDate();
};

export const getIntervalTimeOptions = () => {
	const ret: ISelectBoxOption<number>[] = [];

	const selectedDate = new Date();
	for (let x = 0; x <= 23; x++) {
		for (let y = 0; y <= 3; y++) {
			const minutes = y * 15;
			selectedDate.setHours(x, minutes, 0);
			ret.push({
				title: getDefaultTimeStringValue(selectedDate),
				value: x * 60 + minutes,
			});
		}
	}

	return ret;
};

export const createKeyDateSelection = (
	keyDate: Api.IKeyDate,
	template?: TemplateOrTemplateFilter
): IKeyDateSelection => {
	return {
		contactSelection: {
			bulkFilterRequest: {
				contactFilterRequest: {
					criteria: [
						{
							property: Api.ContactFilterCriteriaProperty.UpcomingKeyDatesNotScheduled,
							value: keyDate?.kind,
						},
					],
				},
			},
		},
		kind: keyDate?.kind,
		month: keyDate?.month,
		template,
	};
};

export const getCreateCampaignRequestForKeyDate = (
	keyFact: Api.IKeyFact,
	template?: Api.ITemplate,
	request?: Omit<ICreateCampaignRequest, 'context' | 'type'>
): ICreateCampaignRequest<IKeyDateSelection> => {
	return {
		...(request || {}),
		context: createKeyDateSelection(keyFact?.keyDate, template),
		type: 'KeyDate',
	};
};

export const sortContactTags = ({ tags, priorityTags = [] }: { tags: string[]; priorityTags?: string[] }): string[] => {
	return tags.sort((a, b) => {
		if (priorityTags.includes(a)) {
			return -1;
		}
		if (priorityTags.includes(b)) {
			return 1;
		}
		return 0;
	});
};

// Sorted tags shown... limit 3, with overflow text
export const getVisibleTags = (
	entity: Api.EntityViewModel,
	highPriorityTags?: Api.ObservableCollection<Api.TagViewModel>,
	count?: number
): [string[], string[]] => {
	if (!!entity && !!entity.tags && entity.tags.length > 0) {
		const highlighted =
			(!!highPriorityTags && highPriorityTags.length > 0 ? highPriorityTags.map(x => x.value) : null) || [];

		// bring the highlighted tags to the front of the list, then tags with alerts... etc
		// only show the first 3
		const visibleTags = [...(entity.tags || [])].sort((a, b) => {
			if (highlighted.indexOf(a) >= 0) {
				return -1;
			} else if (highlighted.indexOf(b) >= 0) {
				return 1;
			} else if (entity instanceof Api.ContactViewModel && !!entity.getTagAlertForTagValue(a)) {
				return -1;
			} else if (entity instanceof Api.ContactViewModel && !!entity.getTagAlertForTagValue(b)) {
				return 1;
			}
			return 0;
		});
		const tags = visibleTags.slice(0, count ?? 3);
		const extraTags = visibleTags.slice(count ?? 3);
		return [tags, extraTags];
	}

	return [[], []];
};

export const getCampaignCalendarDateRangeStringValue = (start: moment.MomentInput, end?: moment.MomentInput) => {
	let completedDayStringValue = '';
	if (!end) {
		return `${moment(start).format('MMMM Do')}`;
	}

	const startMoment = moment(start);
	const endMoment = moment(end);
	const startDay = startMoment.get('day');
	const endDay = endMoment.get('day');
	const startMonth = startMoment.get('month');
	const endMonth = endMoment.get('month');
	if (startDay !== endDay || startMonth !== endMonth) {
		completedDayStringValue = moment(end).format(`${startMonth !== endMonth ? 'MMMM ' : ''}Do`);
	}

	return `${moment(start).format('MMMM Do')}${completedDayStringValue ? ` \u2014 ${completedDayStringValue}` : ''}`;
};

export const getCampaignCalendarDateRangeValue = (start: moment.MomentInput, end?: moment.MomentInput) => {
	let completedDayStringValue = '';
	if (!end) {
		return `${moment(start).format('DD')}`;
	}
	const startMoment = moment(start);
	const endMoment = moment(end);
	const startDay = startMoment.get('day');
	const endDay = endMoment.get('day');
	const startMonth = startMoment.get('month');
	const endMonth = endMoment.get('month');

	if (startDay !== endDay || startMonth !== endMonth) {
		completedDayStringValue = moment(end).format(`${startMonth !== endMonth ? 'MMMM ' : ''}Do`);
	}

	return `${moment(start).format('DD')}${completedDayStringValue ? ` \u2014 ${completedDayStringValue}` : ''}`;
};

export const TinyMCEUndoRedo = 'undo redo';
export const TinyMCEToolbarBoldItalicUnderline = ` bold italic underline `;
export const TinyMCEToolbarBase = ` ${TinyMCEUndoRedo} | ${TinyMCEToolbarBoldItalicUnderline} `;
export const TinyMCEToolbarFonts = ' fontsizeselect | fontselect ';
export const TinyMCEToolbarColor = ' forecolor ';
export const TinyMCEToolbarHyperlink = ' link ';
export const TinyMCEToolbarInsert = ' insertmenu ';
export const TinyMCESignatureToolbarInsert = ' insertsigmenu ';
export const TinyMCEToolbarTable = ' table ';
export const TinyMCEToolbarList = ' listmenu ';
export const TinyMCEToolbarAlign = ' alignmentmenu ';
export const TinyMCEToolbarMerge = ' mergefieldmenu ';
export const ToolbarAIDA = `${TinyMCEToolbarBoldItalicUnderline} | fontsizeselect | ${TinyMCEToolbarColor} | ${TinyMCEToolbarList} | link | emoticons`;
export const ToolbarDefault = `${TinyMCEToolbarBoldItalicUnderline} | fontsizeselect | ${TinyMCEToolbarColor} | ${TinyMCEToolbarList} | link insert-image`;
export const ToolbarLight = `${TinyMCEToolbarBoldItalicUnderline} | ${TinyMCEToolbarList} | link`;
export const ToolbarUnsubscribe = `${TinyMCEToolbarBase} | fontsizeselect | ${TinyMCEToolbarColor}`;
export const ToolbarNotes = `${TinyMCEToolbarBoldItalicUnderline} | ${TinyMCEToolbarList} link insert-image`;
export const ToolbarAdminNotes = `${TinyMCEToolbarBase} | fontsizeselect | ${TinyMCEToolbarColor} ${TinyMCEToolbarList} | link | file-attachments`;
export const ToolbarFull = `${TinyMCEToolbarBase} | ${TinyMCEToolbarColor} | ${TinyMCEToolbarFonts} | ${TinyMCEToolbarInsert} | ${TinyMCEToolbarTable} | ${TinyMCEToolbarList} | ${TinyMCEToolbarAlign} | ${TinyMCEToolbarMerge}|emoticons`;
export const ToolbarSmall = `${TinyMCEToolbarBoldItalicUnderline} | ${TinyMCEToolbarColor} | ${TinyMCEToolbarFonts} | ${TinyMCEToolbarList} | link`;
export const nodeListToArray = <T extends HTMLElement = HTMLElement>(nodes?: NodeList) => {
	if (nodes) {
		return Array.prototype.slice.call(nodes) as T[];
	}

	return [] as T[];
};

export const ToolbarSignature = `${TinyMCEToolbarBase} | ${TinyMCEToolbarColor} | ${TinyMCEToolbarFonts} | ${TinyMCESignatureToolbarInsert} | ${TinyMCEToolbarTable} | ${TinyMCEToolbarList} | ${TinyMCEToolbarAlign}`;

/**
 * @param resourceSelectorId
 * @returns User-friendly display name (singular form, capitalized)
 */
export const getResourceSelectorIdDisplayName = (resourceSelectorId: Api.ResourceSelectorId, plural = false) => {
	switch (resourceSelectorId) {
		case Api.ResourceSelectorId.HouseAnniversaries:
			return `House Anniversar${plural ? 'ies' : 'y'}`;
		case Api.ResourceSelectorId.HappyBirthday:
			return `Birthday${plural ? 's' : ''}`;
		case Api.ResourceSelectorId.FinancialReview:
			return `Client Review${plural ? 's' : ''}`;
		case Api.ResourceSelectorId.PolicyRenew:
			return `Renewal${plural ? 's' : ''}`;
		case Api.ResourceSelectorId.Turning65:
			return 'Turning 65';
		case Api.ResourceSelectorId.Turning72:
			return 'Turning 72';
		case Api.ResourceSelectorId.Turning73:
			return 'Turning 73';
		default:
			return resourceSelectorId;
	}
};

export const getKeyDateKindFromResourceSelectorId = (resourceSelectorId: Api.ResourceSelectorId): Api.KeyDateKind => {
	switch (resourceSelectorId) {
		case Api.ResourceSelectorId.HouseAnniversaries:
			return Api.KeyDateKind.Anniversary;
		case Api.ResourceSelectorId.HappyBirthday:
			return Api.KeyDateKind.Birthday;
		case Api.ResourceSelectorId.FinancialReview:
			return Api.KeyDateKind.FinancialReview;
		case Api.ResourceSelectorId.PolicyRenew:
			return Api.KeyDateKind.Renewal;
		case Api.ResourceSelectorId.Undefined:
			return Api.KeyDateKind.Unknown;
		case Api.ResourceSelectorId.Turning65:
			return Api.KeyDateKind.Turning65;
		case Api.ResourceSelectorId.Turning72:
			return Api.KeyDateKind.Turning72;
		case Api.ResourceSelectorId.Turning73:
			return Api.KeyDateKind.Turning73;
		case Api.ResourceSelectorId.TurningXX:
			return Api.KeyDateKind.TurningXX;
		case Api.ResourceSelectorId.Custom1:
			return Api.KeyDateKind.Custom1;
		case Api.ResourceSelectorId.Custom2:
			return Api.KeyDateKind.Custom2;
		case Api.ResourceSelectorId.Custom3:
			return Api.KeyDateKind.Custom3;
		case Api.ResourceSelectorId.Custom4:
			return Api.KeyDateKind.Custom4;
		case Api.ResourceSelectorId.Custom5:
			return Api.KeyDateKind.Custom5;
		case Api.ResourceSelectorId.Custom6:
			return Api.KeyDateKind.Custom6;
		case Api.ResourceSelectorId.Custom7:
			return Api.KeyDateKind.Custom7;
		case Api.ResourceSelectorId.Custom8:
			return Api.KeyDateKind.Custom8;
		case Api.ResourceSelectorId.Custom9:
			return Api.KeyDateKind.Custom9;
		case Api.ResourceSelectorId.Custom10:
			return Api.KeyDateKind.Custom10;
		default:
			return null;
	}
};

export const contactIsFromEnabledIntegration = (
	contact: Api.ContactViewModel,
	integrations: Api.IAccountIntegrations
): boolean => {
	const source = contact.source;

	return (
		!!source &&
		((source === Api.IntegrationSources.HawkSoft && integrations.hawkSoft?.enabled) ||
			(source === Api.IntegrationSources.AMS360 && integrations.ams360?.enabled) ||
			(source === Api.IntegrationSources.Redtail && integrations.redtail?.enabled) ||
			(source === Api.IntegrationSources.Eclipse && integrations.eclipse?.enabled) ||
			(source === Api.IntegrationSources.Clio && integrations.clio?.enabled) ||
			(source === Api.IntegrationSources.EpicCsv && integrations.epicCsv?.enabled) ||
			(source === Api.IntegrationSources.EzLynxCsv && integrations.ezLynxCsv?.enabled) ||
			(source === Api.IntegrationSources.QQCatalyst && integrations.qqCatalyst?.enabled) ||
			(source === Api.IntegrationSources.Wealthbox && integrations.wealthbox?.enabled) ||
			source === Api.IntegrationSources.InternalLevitate ||
			(source === Api.IntegrationSources.MergeAccounting && integrations.mergeAccounting?.enabled) ||
			(source === Api.IntegrationSources.MergeCrm && integrations.mergeCrm?.enabled) ||
			(source === Api.IntegrationSources.GeneralCsv && integrations.generalCsv?.enabled) ||
			(source === Api.IntegrationSources.MyCase && integrations.myCase?.enabled) ||
			(source === Api.IntegrationSources.DonorPerfect && integrations.donorPerfect?.enabled))
	);
};

export const getContactSource = (contact: Api.ContactViewModel, integrations: Api.IAccountIntegrations) => {
	const source = contact.source;

	if (source === Api.IntegrationSources.MergeAccounting) {
		return getMergeLinkProviderDisplayName(integrations);
	}

	if (source === Api.IntegrationSources.MergeCrm) {
		return getMergeLinkProviderDisplayName(integrations);
	}

	if (source === Api.IntegrationSources.EpicCsv) {
		return 'Epic';
	}

	if (source === Api.IntegrationSources.EzLynxCsv) {
		return 'EzLynx';
	}

	if (source === Api.IntegrationSources.GeneralCsv) {
		return integrations.generalCsv.generalCsvProvider;
	}

	return source;
};

export const logObject = (name: string, object: Record<string, unknown>) => {
	console.log(name, JSON.stringify(object, undefined, 5));
};

export const getSubverticalOptions = (industry: Api.Industry) => {
	switch (industry) {
		case Api.Industry.Insurance:
			return [
				'Personal P&C',
				'Commercial P&C',
				'Personal Health & Benefits',
				'Group Health & Benefits',
				'MGA / Wholesale',
				'Construction',
				'Wealth & Retirement Planning',
				'Life Insurance',
				'Medicare',
				'Crop Insurance',
				'Other',
			];
		case Api.Industry.Legal:
			return [
				'Bankruptcy',
				'Business Law',
				'Criminal Defense',
				'Estate / Elder / Probate Law',
				'Family Law',
				'General Practice',
				'Litigation',
				'Personal Injury',
				'Real Estate',
				'Immigration',
				'Workers Compensation',
				'Intellectual Property',
				'Mediation',
				'Employment Law',
				'Other',
			];
		case Api.Industry.Financial:
			return [
				'Wealth & Retirement Planning',
				'401K ',
				'Banking',
				'CPA',
				'Employee Benefits',
				'Life Insurance',
				'Mortgage Lender',
				'Registered Investment Advisor',
				'Venture Capital',
				'High-Net-Worth Clients',
				'Other',
			];
		case Api.Industry.RealEstate:
		case Api.Industry.Mortgage:
			return [
				'Residential',
				'Commercial',
				'Mortgage Lender',
				'Property Management',
				'Vacation Properties & Rentals',
				'Recruiting',
				'Other',
			];
		case Api.Industry.Miscellaneous:
			return [
				'B2B',
				'B2C',
				'Recruiting',
				'Consulting',
				'Nonprofit',
				'Education',
				'Manufacturing',
				'Software/Tech',
				'Other',
			];
		case Api.Industry.Accounting:
			return [
				'Public',
				'Government',
				'Forensic',
				'Bookkeeping & Payroll',
				'Auditor',
				'Business Tax Preparation',
				'Individual Tax Preparation',
				'Other',
			];
		case Api.Industry.NonProfit:
			return [
				'Senior Care & Services',
				'Child & Adult Disability Services',
				'Education, Employment, & Literacy',
				'Religious Organization',
				'Member Association',
				'Boys & Girls Clubs',
				'Healthcare Svcs and/or Awareness',
				'Environmental Preservation',
				'Community Development',
				'Food Security & Nutrition',
				'Human Rights & Social Justice',
				'Housing & Poverty',
				'Youth Services',
				'Domestic Violence & Abuse',
				'Substance Abuse Support',
				'Outdoor Ed./Sports & Rec.',
				'Arts & Cultural Exposure',
				'Military Services & Support',
				'Animal Welfare',
				'Other',
			];
		case Api.Industry.HomeMaintenance:
			return [
				'Residential',
				'Commercial',
				'HVAC',
				'Plumbing',
				'Electrical',
				'Refrigeration',
				"Manufacturers' Rep",
				'Distributor',
				'Retail',
				'Roofing',
			];
		default:
			return [];
	}
};

export const industrySupportsSubverticals = (industry: Api.Industry) => {
	return getSubverticalOptions(industry).length > 0;
};

export function isAccountFinancialReviewEnabled(accountPreferences: Api.IAccountPreferences) {
	return !!accountPreferences.resourceSelectorSettings?.FinancialReview?.daysToLookAhead;
}

/**
 * Retrieves all times for a single day in provided increments.
 *
 * @example
 * 	// return [
 * 	// 	{ dataContext: 0, text: '12:00 AM' },
 * 	// 	{ dataContext: 60, text: '1:00 AM' },
 * 	// 	{ dataContext: 120, text: '2:00 AM' },
 * 	//	...
 * 	// ]
 * 	getTimeOptions(60);
 *
 * @param {number} increments - The increments in minutes
 * @returns {ISelectOption<number>[]}
 */
export const getTimeOptions = (idLabel: string, increments: number, startTime?: number, endTime?: number) => {
	const options: ISelectOption<number>[] = [];
	let block = 0;
	while (block < 24 * 60 - 1) {
		if (startTime && startTime >= block) {
			block += increments;
			continue;
		}
		if (endTime && block >= endTime) {
			block += increments;
			continue;
		}

		const hour =
			block === 0 || Math.floor(block / 60) === 0 || Math.floor(block / 60) - 12 === 0 // if is 00:00 (12am) or earlier than 01:00 or 12:00 (noon), set hour to 12
				? 12
				: block / 60 < 12 // test if is in the AM
					? Math.floor(block / 60) // number of minutes / 60 (rounded down to whole number) to get AM hours
					: Math.floor(block / 60) - 12; // number of minutes / 60 (rouded down to whole number) - 12 to get PM hours
		const minutes = block === 0 || block % 60 === 0 ? '00' : block % 60; // remainder of (number of minutes / 60) will be the minutes.

		const timeLabel = `${hour}:${minutes} ${block === 0 || Math.floor(block / 60) < 12 ? 'AM' : 'PM'}`;
		const timeId = `time-option-${idLabel}-${hour}-${minutes}-${
			block === 0 || Math.floor(block / 60) < 12 ? 'am' : 'pm'
		}`;

		options.push({
			dataContext: block,
			id: timeId,
			text: timeLabel,
		});
		block += increments;
	}

	return options;
};

export const getFormattedPhoneNumber = (phone: string) => {
	if (!phone) {
		return '';
	}

	const regex = /\d+/g;
	const parsed = phone.match(regex)?.join('') ?? '';

	if (parsed.length !== 10) {
		return phone;
	}

	const areaCode = parsed.slice(0, 3);
	const first = parsed.slice(3, 6);
	const last = parsed.slice(6, phone.length);
	return `(${areaCode}) ${first}-${last}`;
};

export function fixPhoneNumberAreaCode(value: string) {
	if (!value) {
		return '';
	}
	const regex = /\((\d{2})\)/;
	const replacement = '(0$1)';
	const result = value.replace(regex, replacement);
	return result;
}

export const getPartialFormattedPhoneNumber = (partialPhone: string) => {
	if (!partialPhone) {
		return '';
	}

	if (partialPhone.startsWith('+')) {
		return partialPhone;
	}

	const regex = /\d+/g;
	const parsed = partialPhone.match(regex)?.join('') ?? '';

	if (parsed.length === 0) {
		return '';
	}

	const areaCode = parsed.length <= 3 ? parsed : parsed.slice(0, 3);
	const first = parsed.length > 3 ? parsed.slice(3, parsed.length <= 6 ? parsed.length : 6) : '';
	const last = parsed.length > 6 ? parsed.slice(6, parsed.length <= 10 ? parsed.length : 10) : '';
	const ext = parsed.length > 10 ? parsed.slice(10, parsed.length) : '';
	let result = `(${areaCode})`;
	if (first) {
		result += ` ${first}`;
	}
	if (last) {
		result += `-${last}`;
	}
	if (
		ext ||
		(last &&
			(partialPhone[partialPhone.length - 1] === ' ' || partialPhone[partialPhone.length - 1].toLowerCase() === 'e'))
	) {
		result += ` ext. ${ext}`;
	}
	return result;
};

export const convertDurationFromString = (durationString: string | undefined) => {
	if (!durationString) {
		return null;
	}
	let rest = durationString;

	const parsed: Api.IMeetingDuration = {
		days: 0,
		hours: 0,
		minutes: 0,
		seconds: 0,
	};

	if (durationString.includes('.')) {
		const [days, r] = durationString.split('.');
		parsed.days = parseInt(days, 10);
		rest = r;
	}

	const [h, m, s] = rest.split(':');
	return {
		...parsed,
		hours: parseInt(h, 10),
		minutes: parseInt(m, 10),
		seconds: parseInt(s, 10),
	};
};

export const convertDurationToString = (duration: Api.IMeetingDuration) => {
	const { days, hours, minutes, seconds } = duration;
	const d = days <= 9 ? `0${days}` : days;
	const h = hours <= 9 ? `0${hours}` : hours;
	const m = minutes <= 9 ? `0${minutes}` : minutes;
	const s = seconds <= 9 ? `0${seconds}` : seconds;
	return `${d}.${h}:${m}:${s}`;
};

export const isValidDomain = (domain: string) => {
	const split = domain.split('.');
	const subDomainChars = domain.split('.')[0];
	const topDomain = domain.substr(domain.indexOf('.') + 1);
	if (
		!domain ||
		!domain.includes('.') ||
		subDomainChars.length === 0 ||
		topDomain.length === 0 ||
		(split.length > 1 && split.filter(s => !s).length !== 0)
	) {
		return false;
	}
	return true;
};

export const connectEmailIsDifferent = (userEmail: string, userConnectedEmail: string) => {
	if (userEmail && userConnectedEmail && userEmail.toLowerCase() !== userConnectedEmail.toLowerCase()) {
		return true;
	}
	return false;
};

export const dataAttributes = (data?: Record<string, string>) => {
	if (data) {
		return Object.keys(data).reduce<Record<string, string>>((res, x) => {
			let key = (x || '').trim();
			if (key) {
				const dataKey = key;
				/**
				 * If the key does not start with 'data-', add it
				 */
				if (!key.startsWith('data-')) {
					key = `data-${key}`;
				}
				res[key] = data[dataKey];
			}
			return res;
		}, {});
	}
	return {};
};

export const devElementDataProps = (data?: Record<string, any>) => {
	return {};
};

export const isAddressComplete = (address: Api.IAddress) => {
	return address.address1 && address.city && address.stateProvince && address.postalCode && address.country;
};

export const hasRequiredHwcRecipientAddressFields = (address: Api.IAddress) => {
	return address.address1 && address.city && address.stateProvince && address.postalCode;
};

export const getMultilineFormattedAddress = (address: Api.IAddress) => {
	const countryName = (
		Countries.find(x => x.value === address?.country) || {
			name: address?.country,
		}
	).name;
	return `${address.address1 ? `${address.address1}\n` : ''}${address.address2 ? `${address.address2}\n` : ''}${
		address.city ? `${address.city}, ` : ''
	}${address.stateProvince ? `${address.stateProvince} ` : ''}${address.postalCode ? address.postalCode : ''}\n${
		countryName ? countryName : ''
	}`.trim();
};

export const getFormattedAddress = (address: Api.IAddress) => {
	return `${address.address1 ? address.address1 + ' ' : ''}${address.address2 ? address.address2 : ''}, ${
		address.city ? address.city + ', ' : ''
	}${address.stateProvince ? address.stateProvince + ' ' : ''}${address.postalCode || ''}`.trim();
};

export const getDefaultRichContentForTextingStep = (
	account?: Api.IAccount,
	trigger?: Api.IAutomationTrigger,
	userSession?: Api.UserSessionContext,
	schedule?: Api.IAutomationStepSchedule
) => {
	const firstNameToken = new Token(FirstNamePlaceholder);
	const senderNameToken = new Token(SenderFirstNamePlaceholder);
	let messageHtmlStringValue = `Hi ${firstNameToken.toHTMLString()}, this is ${senderNameToken.toHTMLString()} from ${account?.companyName}.`;
	switch (trigger?._type) {
		case Api.AutomationTriggerType.NewLead: {
			messageHtmlStringValue = `${messageHtmlStringValue}\nYou can text me at this number if you are still looking for assistance.`;
			break;
		}
		case Api.AutomationTriggerType.NewClient:
		case Api.AutomationTriggerType.NewDonor: {
			messageHtmlStringValue = `${messageHtmlStringValue}\nYou can text me at this number in the future should you need anything!`;
			break;
		}
		case Api.AutomationTriggerType.Meeting: {
			messageHtmlStringValue = getRichContentMessageForTextingStep(userSession, trigger, schedule);
			break;
		}
		case Api.AutomationTriggerType.ResourceSelector: {
			const t = trigger as Api.IResourceSelectorAutomationTrigger;
			switch (t.resourceSelector) {
				case Api.ResourceSelectorId.HappyBirthday:
				case Api.ResourceSelectorId.Turning65:
				case Api.ResourceSelectorId.Turning72:
				case Api.ResourceSelectorId.Turning73: {
					messageHtmlStringValue = `${messageHtmlStringValue}\nJust wanted to wish you a happy birthday!`;
					break;
				}
				case Api.ResourceSelectorId.PolicyRenew:
					messageHtmlStringValue = `${messageHtmlStringValue}\nJust a reminder that one of your policies is up for renewals. Please text with any questions.`;
					break;
				default: {
					break;
				}
			}
			break;
		}
		default: {
			break;
		}
	}
	return messageHtmlStringValue ? Api.createRawRichTextContentStateWithText(messageHtmlStringValue) : null;
};

/**
 * Replicates default comparison behavior in c# for string as closely as possible i.e. sorts by letter, then case, not
 * the other way around (js default)
 */
type Comparable = number | string | boolean | Date;
export const orderBy = <T extends Comparable>(a: T, b: T) => {
	if (typeof a === 'string' && typeof b === 'string') {
		return a.localeCompare(b, 'en', {
			ignorePunctuation: true,
			sensitivity: 'accent',
		});
	}

	if (a > b) {
		return 1;
	}
	if (a < b) {
		return -1;
	}
	return 0;
};

export const orderByDescending = <T extends Comparable>(a: T, b: T) => {
	if (typeof a === 'string' && typeof b === 'string') {
		return b.localeCompare(a, 'en', {
			ignorePunctuation: true,
			sensitivity: 'accent',
		});
	}

	if (a < b) {
		return 1;
	}
	if (a > b) {
		return -1;
	}
	return 0;
};

export const getValidAllowLevitateImpersonationUntil = (account: Api.IAccount) => {
	if (!account?.allowLevitateImpersonationUntil) {
		return null;
	}

	const impersonationUntilIsStillValid = moment().isBefore(account.allowLevitateImpersonationUntil);
	return impersonationUntilIsStillValid ? account.allowLevitateImpersonationUntil : null;
};

export const checkSocialCitation = (post: Api.ISocialMediaPost | Api.SocialMediaPostViewModel) => {
	return post?.citations != null && post.citations.length > 0;
};

export const getUnsubscribeReason = (metadata: Api.IUnsubscribeMetadata): string => {
	if (!metadata?.reason) {
		return '';
	}

	switch (metadata.reason) {
		case Api.UnsubscribeReason.ByUser:
			return `by ${metadata?.actor?.name || 'Unknown'}`;
		case Api.UnsubscribeReason.ByAI:
			return 'by Levitate AI';
		case Api.UnsubscribeReason.ByCSVParser:
			return 'by a lead parser integration';
		case Api.UnsubscribeReason.ByIntegration:
			return 'due to integration settings';
		case Api.UnsubscribeReason.ByRecipient:
			return 'due to their "Unsubscribe me" response';
		case Api.UnsubscribeReason.Unknown:
		default:
			return '';
	}
};

export const getTemplateCardGreeting = (cardContent: Api.IRawRichTextContentState) => {
	/** Get document content from content.document */
	const _doc = new DOMParser()?.parseFromString(cardContent?.document, 'text/html')?.body;
	/** Get lev-content element from document */
	const _levContentElement = Array.from(_doc?.getElementsByTagName('lev-content'))?.[0] || null;
	/** Get placeholder element from lev-content element */
	const _placeholder =
		(_levContentElement && _levContentElement?.querySelectorAll('span[data-placeholder]')?.[0]) || null;
	/**
	 * @returns Object of empty strings
	 * @If placeholder does not exist,
	 */
	if (!_placeholder) {
		return {
			greeting: '',
			greetingString: '',
			placeholder: null,
		};
	}
	/** Get first element from lev-content element */
	const _firstElementContent = new DOMParser()?.parseFromString(
		_levContentElement?.firstElementChild?.outerHTML,
		'text/html'
	);
	/** Get first element child from the first element content */
	const firstElementFirstChild =
		_firstElementContent && _firstElementContent?.getElementsByTagName('p')?.[0]?.firstElementChild;
	/**
	 * @If the lev-content element's first element child is the same as the placeholder
	 * - get the first word from the lev-content element's innerHTML
	 * @ElSEIF the first element child exists (a <P> tag) and the first element child's innerHTML is not the same as the placeholder's innerHTML
	 * - get the first word from the first element child's innerHTML
	 * @ELSE get the first word from the first <p> tag element's innerHTML
	 */
	const firstWord =
		_levContentElement.firstElementChild === _placeholder
			? _levContentElement?.innerHTML?.split(' ')?.[0]
			: firstElementFirstChild && firstElementFirstChild?.innerHTML !== _placeholder?.innerHTML
				? firstElementFirstChild?.innerHTML?.split(' ')?.[0]
				: _firstElementContent?.getElementsByTagName('p')?.[0]?.innerHTML?.split(' ')?.[0] || '';
	/** Greeting is comprised of the first word and the placeholder element */
	const _greeting = (Boolean(firstWord) && firstWord + ' ' + _placeholder?.outerHTML) || '';
	/**
	 * Greeting string is used to compare the greeting to the template's content so that it can be removed from the
	 * template's content if it is the same as the greeting.
	 */
	const _greetingString = Boolean(firstWord) && firstWord + ' ' + _placeholder?.innerHTML;
	return {
		greeting: _greeting,
		greetingString: _greetingString,
		placeholder: _placeholder,
	};
};

export const getPlainTextPreviewForRawRichTextContent = (
	content: Api.IRawRichTextContentState,
	options?: {
		maxCharCount?: number;
		maxLineCount?: number;
		startingLineOffset?: number | ((rootElement?: Element, lines?: HTMLParagraphElement[]) => number);
	}
) => {
	let text = '';
	let truncated = false;
	try {
		const doc = new DOMParser().parseFromString(content?.document, 'text/html');
		const levContentElement = Array.from(doc.getElementsByTagName('lev-content'))?.[0];
		const lineElements = levContentElement ? Array.from(levContentElement.getElementsByTagName('p')) : [];

		if (lineElements?.length === 0) {
			text = levContentElement.textContent;
			return {
				text: getShortenedString(text.trim(), options.maxCharCount),
				truncated: text?.length > options.maxCharCount ? true : false,
			};
		}

		let lineIndex = 0;
		if (options?.startingLineOffset !== undefined && options?.startingLineOffset !== null) {
			lineIndex =
				typeof options.startingLineOffset === 'function'
					? options.startingLineOffset(levContentElement, lineElements)
					: options.startingLineOffset;
		}
		const startingLineIndex = lineIndex;

		if (!lineElements?.[lineIndex]?.textContent) {
			if (levContentElement?.textContent) {
				text = text.concat(levContentElement.textContent);
			}
		} else {
			do {
				text = text.concat(lineElements?.[lineIndex]?.textContent);
				text = text.concat(' ');
				lineIndex++;
				if (
					(options?.maxCharCount > -1 && text.length >= options.maxCharCount) ||
					(options?.maxLineCount > -1 && lineIndex - startingLineIndex >= options.maxLineCount)
				) {
					truncated = true;
					break;
				}
			} while (lineIndex < lineElements.length);
		}
	} catch {
		console.log('DOMParser error');
	}
	const truncate = options?.maxCharCount > -1 && text.length > options.maxCharCount;
	if (truncate) {
		truncated = true;
	}
	return {
		text: truncate ? getShortenedString(text.trim(), options.maxCharCount) : text.trim(),
		truncated,
	};
};

export const getLogoUrlForIntegrationProvider = (provider: string) => {
	const providerName = provider?.toLowerCase();
	switch (providerName) {
		case 'salesforce': {
			return salesforceLogoImageUrl;
		}
		case 'quickbooksonline': {
			return quickbooksOnlineLogomarkImageUrl;
		}
		case 'xero': {
			return xeroLogoUrl;
		}
		default: {
			return null;
		}
	}
};

export const getMergeLinkProviderDisplayName = (accountIntegrations: Api.IAccountIntegrations) => {
	if (accountIntegrations?.mergeAccounting) {
		switch (accountIntegrations.mergeAccounting.mergeAccountingProvider) {
			case Api.MergeAccountingProvider.QuickBooksOnline:
				return 'QuickBooks Online';
			case Api.MergeAccountingProvider.Xero:
				return 'Xero';
			default:
				return 'Merge Accounting';
		}
	}

	if (accountIntegrations?.mergeCrm) {
		switch (accountIntegrations.mergeCrm.mergeCrmProvider) {
			case Api.MergeCrmProvider.Salesforce:
				return 'Salesforce';
			default:
				return 'Merge CRM';
		}
	}

	return 'Merge';
};

export const getStatusGroupOptions = (post: Api.SocialMediaPostViewModel) => {
	if (post && [Api.PostStatus.Cancelled, Api.PostStatus.Failed, Api.PostStatus.Unknown].includes(post.status)) {
		return SocialMediaPostStatusDetails.CancelledOrFailed;
	} else if (!post || post.status === Api.PostStatus.Draft) {
		return SocialMediaPostStatusDetails.NewOrDraft;
	} else {
		return SocialMediaPostStatusDetails.NotCancelledFailedDraftOrUnknown;
	}
};

export const isScheduledOrPendingOrDraftOrNew = (
	post: Api.SocialMediaPostViewModel | Api.SocialMediaPostReportViewModel
) => {
	return !post ||
		post?.status === Api.PostStatus.Scheduled ||
		post?.status === Api.PostStatus.Pending ||
		post?.status === Api.PostStatus.Draft
		? true
		: false;
};

export const mapSocialMediaTypesToPostTargets = (providerTypes: Api.SocialMediaType[]) => {
	const targets: Api.IPostTarget[] = providerTypes?.map(x => {
		return { provider: x };
	});
	return targets;
};

export const getProviderTypesFromPostTargest = (targets: Api.IPostTarget[]) => {
	const types: Api.SocialMediaType[] = targets?.map(x => {
		return x.provider;
	});
	return types;
};

export const sortPlatformTargets = (platforms: Api.IPostTarget[]) => {
	const sortedPlatforms = platforms
		?.sort((u, v) =>
			u?.state?.lastConnectionState?.postTargetDisplayName === v?.state?.lastConnectionState?.postTargetDisplayName
				? 0
				: u?.state?.lastConnectionState?.postTargetDisplayName > v?.state?.lastConnectionState?.postTargetDisplayName
					? 1
					: -1
		)
		?.sort((c, d) =>
			c?.state?.lastConnectionState?.state === d?.state?.lastConnectionState?.state
				? 0
				: c?.state?.lastConnectionState?.state > d?.state?.lastConnectionState?.state
					? 1
					: -1
		)
		?.sort((a, b) => (a.provider === b.provider ? 0 : a.provider > b.provider ? 1 : -1));
	return sortedPlatforms || [];
};

export const getRatingIntFromText = (ratingText: string) => {
	const numberMap = {
		ONE: 1,
		TWO: 2,
		THREE: 3,
		FOUR: 4,
		FIVE: 5,
	};
	return numberMap[ratingText as keyof typeof numberMap];
};

export const isCampaignScheduledToSend = (campaign: Api.ICampaign) => {
	return !campaign.cancelledDate && !campaign.completedDate && !!campaign.schedule;
};

export const hasCampaignStarted = (campaign: Api.ICampaign) =>
	isCampaignScheduledToSend(campaign) ? new Date(campaign.schedule.startDate) < new Date() : false;

export const campaignQueuedCount = (campaign: Api.ICampaign) =>
	campaign.totalEmails - campaign.sentSuccessfullyCount - campaign.failedCount - (campaign.noEmailAddressCount ?? 0);

export const isCampaignInFinalStatus = (campaign: Api.ICampaign) =>
	campaign.status === Api.EmailSendStatus.Complete ||
	campaign.status === Api.EmailSendStatus.Cancelled ||
	campaign.status === Api.EmailSendStatus.Rejected;

export function formatDateCriteria<T>(property: T, value?: Api.IRange<Date>) {
	if (!value) {
		return null;
	}

	const f = 'YYYY-MM-DD';
	const criteria = {
		criteria: [
			{
				op: Api.FilterOperator.Gt,
				property,
				value: moment(value?.start).format(f),
			},
			...(value?.end
				? [
						{
							op: Api.FilterOperator.Lt,
							property,
							value: moment(value.end).add(1, 'day').format(f),
						},
					]
				: []),
		],
		op: Api.FilterOperator.And,
	};

	return criteria;
}
export const formatDateRangeString = (value?: Api.IRange<Date | null>): string => {
	if (!value?.start) {
		return '';
	}

	const f = 'MM/DD/YYYY';
	return `${moment(value.start).format(f)} - ${moment(value.end).format(f)}`;
};

export function getDefaultOwnedByContactFilterFilterCriteria(
	userSession: Api.UserSessionContext,
	sendEmailFromUserId?: string
): Api.IOwnershipFilter | null {
	return !userSession.account.preferences?.showAllContactsByDefault
		? {
				criteria: {
					property: Api.ContactFilterCriteriaProperty.OwnedBy,
					value: sendEmailFromUserId ?? userSession.user.id,
				},
			}
		: null;
}

export const getCategoriesByName = (catOptions: ITemplatesIndustryWithCategories[], selectedOption: string) => {
	const list = catOptions.filter(x => x.categoryNames.includes(selectedOption));
	const newList: Api.ITemplateStatus[] = list.map(x => {
		return {
			industry: x.industry,
			category: selectedOption,
		};
	});
	return newList;
};

export const getCategoryPathByCampaignType = (campaign: CampaignType, isCombo?: boolean) => {
	let categoriesType = '';
	switch (campaign) {
		case CampaignType.Social:
			categoriesType = 'socialCategories';
			break;
		case CampaignType.Blog:
			categoriesType = 'blogCategories';
			break;
		case CampaignType.Email:
			categoriesType = 'categories';
			break;
		default:
			categoriesType = 'categories';
			break;
	}
	if (campaign === CampaignType.Social && isCombo) {
		categoriesType = 'socialCategories';
	}
	return categoriesType;
};

export const handleNameUpdateForSelectedImage = (
	image: IPixabayImage,
	options?: IPixabayImageSearchOptions
): Api.IFileAttachmentWithURL => {
	const name = image.previewURL.split('/').pop();
	const fileName = name.split('_').shift();
	return {
		...image,
		fileName,
		url:
			Math.max(options?.min_height || 0, options?.min_width || 0) > 640 && Boolean(image.largeImageURL)
				? image.largeImageURL
				: image.webformatURL,
		fileSize: image.imageSize,
		source: Api.IAttachmentUploadSource.Pixabay,
	};
};
/**
 *
 * @param hexCode { string } with hash (#) or without hash
 * @param alpha { number } alpha value for the color, can be a number between 0 and 1 or a number between 0 and 100
 * @returns rgba color if the hex code is valid, otherwise an empty string
 */
export function hexToRGBA(hexCode: string, alpha = 1): string {
	if (!hexCode) {
		return '';
	}
	let hex = hexCode.replace('#', '');
	if (hex.length === 3) {
		hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
	}
	const r = parseInt(hex.slice(0, 2), 16);
	const g = parseInt(hex.slice(2, 4), 16);
	const b = parseInt(hex.slice(4, 6), 16);

	if (alpha > 1 && alpha <= 100) {
		alpha = alpha / 100;
	}
	return `rgba(${r},${g},${b},${alpha})`;
}

export const applyDefaultExcludedTagsToContactsFilterRequest = (
	emailComposer: ComposeEmailViewModel,
	contactFilterRequest?: Api.IContactsFilterRequest
): Api.IContactsFilterRequest => {
	const sorted = Api.VmUtils.sortContactFilterCriteria(contactFilterRequest);
	const excludes = Array.from(Api.DefaultBulkSendExcludedTags);

	let newCriteria = Api.VmUtils.concatContactFilterCriteria(
		[
			...sorted.compound,
			...sorted.filters,
			...sorted.searches.filter(
				x =>
					x.op !== Api.FilterOperator.Not ||
					(x.op === Api.FilterOperator.Not &&
						!excludes.find(y => y.toLocaleLowerCase() === x.value?.toLocaleLowerCase()))
			),
		],
		Array.from(Api.DefaultBulkSendExcludedTags).map(x => ({
			op: Api.FilterOperator.Not,
			property: Api.ContactFilterCriteriaProperty.Tag,
			value: x,
		}))
	);

	if (
		emailComposer?.emailMessage?.isSendFromOption(Api.SendEmailFrom.ContactOwner) ||
		emailComposer?.emailMessage?.isSendFromOption(Api.SendEmailFrom.SelectedUser)
	) {
		newCriteria = [
			...newCriteria,
			{
				op: Api.FilterOperator.Not,
				property: Api.ContactFilterCriteriaProperty.PrivateContacts,
				value: 'Private',
			},
		];
	}

	return { criteria: newCriteria };
};

export const getUniqueIdForSuggestion = (suggestion: IContentCalendarSelectedSuggestion) => {
	return suggestion.dragDropId
		? suggestion.dragDropId
		: suggestion.id
			? `${suggestion.id}-${suggestion.templateReference.templateId}`
			: suggestion.templateReference.templateId;
};

export const senderIsNotCurrentUser = (emailMessage: Api.EmailMessageViewModel<any, any, any>) => {
	const { isSendFromOption } = emailMessage;

	if (!isSendFromOption) {
		return false;
	}
	return isSendFromOption(Api.SendEmailFrom.ContactOwner) || isSendFromOption(Api.SendEmailFrom.SelectedUser);
};

/**
 * Updates the filter criteria for an email composer with the new values
 */
export const updateContactFilterSendFrom = ({
	emailComposer,
	newSendFrom,
	newSendFromUserId,
	partialCurrentUser,
}: {
	emailComposer: ComposeEmailViewModel;
	newSendFrom: Api.ISendEmailFrom;
	newSendFromUserId: string | null;
	partialCurrentUser: Api.IUser;
}) => {
	const sendFromUser =
		newSendFrom === Api.SendEmailFrom.CurrentUser
			? partialCurrentUser
			: newSendFromUserId
				? { id: newSendFromUserId }
				: null;

	const userId = newSendFromUserId;
	const emailOptions = emailComposer.emailMessage.options;
	if (userId) {
		const fromCurrentUser = partialCurrentUser.id === userId;
		emailOptions.sendEmailFrom = newSendFrom;
		emailOptions.sendEmailFromUser = sendFromUser;
		emailOptions.sendEmailFromUserId = fromCurrentUser ? undefined : userId;

		emailComposer.emailMessage.contactsFilterRequest.ownershipFilter = {
			criteria: {
				property: Api.ContactFilterCriteriaProperty.OwnedBy,
				value: userId,
			},
		};
	} else {
		emailOptions.sendEmailFrom = Api.SendEmailFrom.ContactOwner;
		emailOptions.sendEmailFromUser = undefined;
		emailOptions.sendEmailFromUserId = undefined;
		emailComposer.emailMessage.contactsFilterRequest.ownershipFilter = undefined;
	}

	emailComposer.updateApproximationForFilter();
	emailComposer.getNextBatchOfRecipients();
};

export const getUserFromOwnershipFilter = (filter?: Api.IOwnershipFilter) => {
	return filter?.criteria?.property === Api.ContactFilterCriteriaProperty.OwnedBy ? filter.criteria.value : null;
};

export const areOwnershipFiltersEqual = (
	currentUserId: string,
	a: Api.IOwnershipFilter | null,
	b: Api.IOwnershipFilter | null
) => {
	if (!a && !b) {
		return true;
	}

	if (!a || !b) {
		return false;
	}

	if (
		a.criteria.property === Api.ContactFilterCriteriaProperty.OwnedBy &&
		b.criteria.property === Api.ContactFilterCriteriaProperty.OwnedBy
	) {
		// no value and current user id are equivalent
		if (
			(!a.criteria.value || a.criteria.value.length === 0 || a.criteria.value === currentUserId) &&
			(!b.criteria.value || b.criteria.value.length === 0 || b.criteria.value === currentUserId)
		) {
			return true;
		}

		return a.criteria.value === b.criteria.value;
	}

	return (
		a.criteria?.property === b.criteria?.property &&
		a.criteria.value === b.criteria.value &&
		a.criteria?.values?.length === b.criteria.values?.length
	);
};

export function getContentLength(targetChoice: Api.SocialMediaType) {
	if (targetChoice === Api.SocialMediaType.Instagram) {
		return Api.SocialMediaPreviewLimits.Instagram;
	} else if (targetChoice === Api.SocialMediaType.LinkedIn) {
		return Api.SocialMediaPreviewLimits.LinkedIn;
	}
	return Api.SocialMediaPreviewLimits.FaceBook;
}

export const isExemptFromRegistration = (accountId: string) => {
	const registrationExemptAccounts = [
		'03081717-857c-4149-8b13-05311a1c5d6a',
		'318933ef-76bd-4e75-bdfc-287a38c4c800',
		'b9cd4e85-5fc1-4cfd-811b-3f585145e71b',
		'3abcd3d1-ce02-4272-a12a-3c3a25c7a34a',
		'31caffb1-8e8b-49ab-a4d1-83b2cb0404f5',
		'be7fb929-b317-44bc-b5fa-dc7d188b20aa',
		'73083ac2-bd8b-44f9-9def-610e304ca116',
		'64d2d70d-4400-4fe3-a885-caa36ff812b4',
		'5b547f37-8b7e-40a2-b423-6fdd418e14dd',
		'29185fc5-3cba-4ca2-a78c-bc377685d251',
		'ca6a0cb9-78e0-40ba-b2fe-b188b699e01d',
		'1e126cb8-3882-4f55-8b6f-e1171f15fdcb',
		'a071cc39-d26b-4f86-9a51-d47885a2d1fc',
		'470b1351-cc30-4d3c-b728-39a176a7e984',
		'aaa314aa-9fd9-40ca-a1d5-a2ae6238b38d',
		'e27b9da2-6d79-444d-9436-0f9dd1ca6f7d',
		'6c9cb8fe-4fc3-4889-8edd-1afb0a07a0ad',
		'885e2925-02d2-4a87-82ea-d3bd13273aba',
		'0ae5e5ae-1bf9-4716-84d4-b281b6b3f221',
		'37bdee2e-2037-48e0-9f49-ae9abcbf5720',
		'60c1ddbc-00dd-47e2-b7b8-3d515323d8a7',
		'0a1aab6b-5efb-454f-9f57-b73b12045845',
		'389e4e75-dc80-463c-9847-8a68ca4f07c3',
		'7ceee11d-8486-43f0-a822-93a312e0f358',
		'e3d506b3-c6bd-4e76-a4cc-58bded2d6ebd',
		'1a6198ea-f7dc-4cf0-8003-c6c82c49fc31',
		'04b30dbb-bfe2-4877-ab9c-ad9693b9152d',
		'7edbdd18-ce28-4168-abd2-34f6940019db',
		'ddcc0c01-70b7-4fe4-8551-64ec0e181284',
		'8d57a3bd-6d94-406a-8b7e-2fb8afb0cf70',
		'aaf2fe54-773e-40f5-bd4f-8fc3574311bb',
		'd2158c99-72e7-46de-a030-e78156c71ea7',
		'ca556e00-1767-490a-854f-286c8bc9e165',
		'0caba957-dc08-4bfb-9218-2d9612ab8b26',
		'7eef2a9b-f563-4416-9e4e-9d44a3496486',
		'bc862a87-29f3-4bea-8d26-7b71a6fd1eea',
		'0738add2-189a-4f52-8dcf-f12ee6f03b55',
		'f54c5fa0-966b-4cca-bd11-b1233ca91490',
		'eaeee16a-5c4f-4b9b-a139-7d2391d06302',
		'fc85b93e-3d30-45b3-b777-f67d9b4abc6c',
	];
	return registrationExemptAccounts.includes(accountId);
};

export function logoutAndClearCaches() {
	SharedAppState.AppState[SharedAppState.UserSessionViewModelKey].logout();
	SharedAppState.AppState[SharedAppState.TextMessagingViewModelKey]?.fullReset();
	reactQueryClient.removeQueries();
}
