import * as React from 'react';
import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { styleSheet } from './styles';
import { Button } from '../../../../../web/components/Button';
import { TextArea } from '../../../../../web/components/TextArea';
import { ISelectOption, Select } from '../../../../../web/components/Select';
import {
	useGetScorecardQuery,
	invalidateGetScorecard,
	useEvaluatePhraseMutation,
	useGetPhoneCallQuery,
	useGetTranscriptionSpeakerLineViewQuery,
} from '../../../../queries';
import { LiveScorecard } from '../../../../components/ai/LiveScorecard';
import { SpeakerLinesComponent } from '../../../../components/CallRecordingDetails/components/SpeakerLinesComponent';
import { useTranscription } from '../../../../entities/PhoneCall/useTranscription';

const callTrackOptions = [Api.CallTrack.Inbound, Api.CallTrack.Outbound, Api.CallTrack.Both].map(track => {
	return {
		dataContext: track,
		id: track,
		text: track,
	};
});

export const PhoneCallTester = ({
	phoneCallId,
}: {
	phoneCallId: string;
}) => {
	const [finalPhrase, setFinalPhrase] = React.useState('');
	const [callTrack, setCallTrack] = React.useState<Api.CallTrack>(Api.CallTrack.Inbound);
	const { createTranscription } = useTranscription<Api.ITranscription>();

	const getPhoneCallQuery = useGetPhoneCallQuery({ phoneCallId });
	const phoneCall: Api.IPhoneCall = getPhoneCallQuery.data ?? null;

	const getScorecardQuery = useGetScorecardQuery({ scorecardId: phoneCall?.activeScorecardId });

	const getTranscriptionQuery = useGetTranscriptionSpeakerLineViewQuery({
		transcriptionId: phoneCall?.transcriptionId,
	});
	const evaluatePhraseMutation = useEvaluatePhraseMutation({
		scorecardId: phoneCall?.activeScorecardId,
	});

	const scorecard: Api.IScorecard = getScorecardQuery.data ?? null;
	const transcription: Api.ITranscriptionSpeakerLines = getTranscriptionQuery.data ?? null;

	const sendFinalPhrase = async () => {
		await evaluatePhraseMutation.mutateAsync({ phrase: finalPhrase, callTrack });
		invalidateGetScorecard(phoneCall?.activeScorecardId);
		setFinalPhrase('');
	};

	const generateText = (phrase: string) => {
		setFinalPhrase(
			`Lorem ipsum dolor sit amet, consectetur adipiscing elit, ${phrase} sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`
		);
	};

	const onSpeakerLineClicked = async (speakerLine: Api.ISpeakerLine) => {
		setFinalPhrase(speakerLine.line);
		const speakerLineCallTrack = speakerLine.speaker === 'Lead' ? Api.CallTrack.Outbound : Api.CallTrack.Inbound;
		await evaluatePhraseMutation.mutateAsync({ phrase: speakerLine.line, callTrack: speakerLineCallTrack });
		invalidateGetScorecard(phoneCall?.activeScorecardId);
	};

	const testFullLeadSpeakerLine = async () => {
		const leadSpeakerLines = transcription?.speakerLines ?? [];

		const outboundPhrases = leadSpeakerLines
			.filter(x => x.speaker === 'Lead')
			.map(x => x.line)
			.join('  ');
		const inboundPhrases = leadSpeakerLines
			.filter(x => x.speaker !== 'Lead')
			.map(x => x.line)
			.join('  ');

		await evaluatePhraseMutation.mutateAsync({ phrase: outboundPhrases, callTrack: Api.CallTrack.Outbound });
		await evaluatePhraseMutation.mutateAsync({ phrase: inboundPhrases, callTrack: Api.CallTrack.Inbound });

		invalidateGetScorecard(phoneCall?.activeScorecardId);
	};

	const transcribeCall = async () => {
		await createTranscription(phoneCallId);
	};

	const selectCallTrackOption = (option: ISelectOption) => {
		setCallTrack(option.dataContext);
	};

	if (!scorecard) {
		return null;
	}

	// For developers you can set this to true to see free form text entry that is easier to test phrases than
	// finding a call with matching phrases without a scorecard to test.
	const showCenterTestArea = false;

	return (
		<div className={css(styleSheet.container)}>
			<div className={css(styleSheet.liveScorecard)}>
				<LiveScorecard scorecard={scorecard} />
			</div>

			{showCenterTestArea ? (
				<div className={css(styleSheet.testerContainer)}>
					<TextArea
						className={css(styleSheet.finalPhrase)}
						inputId='final-phrase'
						placeholder='Phrase to send to API to check'
						value={finalPhrase}
						onChange={e => setFinalPhrase(e.target.value)}
						rows={10}
					/>
					<Select
						options={callTrackOptions}
						onOptionClick={selectCallTrackOption}
						selectedOption={callTrackOptions.find(x => x.dataContext === callTrack)}
					/>
					<div>
						<div className={css(styleSheet.generateButton)}>
							<span>Generate text for </span>
							{scorecard.topicProgressions.map(topicProgression => {
								return (
									<Button
										key={topicProgression.topicIdentifier}
										onClick={() => generateText(topicProgression.topicIdentifier)}
										label={topicProgression.topicIdentifier}
									/>
								);
							})}
						</div>
					</div>
					<Button onClick={() => sendFinalPhrase()} label='Send Phrase' />
				</div>
			) : null}

			{phoneCall?.transcriptionId ? (
				<div className={css(styleSheet.testerContainer)}>
					{transcription?.speakerLines?.length ? (
						<SpeakerLinesComponent
							isLoading={false}
							isRead={true}
							isSpeakerLinesReady={true}
							isTranscriptionCompleted={true}
							speakerLines={transcription?.speakerLines}
							transcriptionId={phoneCall?.transcriptionId}
							onClick={onSpeakerLineClicked}
						/>
					) : null}

					<div>
						<label>
							Clicking the button below will re-run the phrases through the scoring for each side of the call.
						</label>
						<Button onClick={testFullLeadSpeakerLine} label='Re-evaluate transcript' />
					</div>
				</div>
			) : null}

			{!phoneCall?.transcriptionId ? <Button onClick={transcribeCall} label='Transcribe Call' /> : null}
		</div>
	);
};
