import { Tabs } from '../../components/Tabs';
import { LeadView } from '../../components/leadView/LeadView';
import { ViewportContext } from '../../hooks/useViewport';
import { brandSecondary, white } from '../../styles/colors';
import { CallLogsTab } from './components/CallLogsTab';
import { IncomingCallsTab } from './components/IncomingCallsTab';
import { styleSheet } from './styles';
import { css } from 'aphrodite';
import { useContext } from 'react';
import * as React from 'react';

export const CallLogContainer: React.FC = () => {
	const { isBelowSmall } = useContext(ViewportContext);

	return (
		<Tabs
			defaultTab='call-log'
			tabs={[
				{
					content: (
						<div className={css(styleSheet.viewContainer)}>
							<CallLogsTab />
						</div>
					),
					name: 'call-log',
					renderLabel: selectedTab => (
						<LeadView.Title
							color={selectedTab !== 'call-log' ? brandSecondary : white}
							size='small'
							title={isBelowSmall ? 'Call Log' : 'Call Logs'}
						/>
					),
				},
				{
					content: (
						<div className={css(styleSheet.viewContainer)}>
							<IncomingCallsTab />
						</div>
					),
					name: 'incoming',
					renderLabel: selectedTab => (
						<LeadView.Title
							color={selectedTab !== 'incoming' ? brandSecondary : white}
							size='small'
							title={isBelowSmall ? 'Incoming' : 'Incoming Calls'}
						/>
					),
				},
			]}
		/>
	);
};
