import { StyleSheet } from 'aphrodite';
import { CssSelectors } from '../../../../../web/styles/styles';

export const styleSheet = StyleSheet.create({
	formControlContainer: {
		display: 'flex',
		gap: 16,
	},
	formControl: {
		margin: '0 0 16px',
		flex: '1',
	},
	info: {
		display: 'flex',
		flexDirection: 'column',
		gap: 8,
		flex: '1',
	},
	footer: {
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: 32,
	},
	header: {
		display: 'flex',
		justifyContent: 'space-between',
		paddingBottom: 30,
	},
	title: {
		fontSize: '18px',
	},
	modalOverrides: {
		...CssSelectors.allDescendants(
			{
				border: 'none',
				maxWidth: 1200,
				width: 1200,
			},
			'.modal-overlay-content'
		),
	},
	container: {
		width: '100%',
		maxHeight: '80vh',
		overflow: 'auto',
		paddingLeft: 36,
		paddingRight: 36,
	},
	tableStyles: {
		width: '100%',
		maxHeight: 400,
		overflow: 'auto',
	},
	suggestionsContainer: {
		display: 'flex',
		flexDirection: 'column',
	},
});
