import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../../web/ReactQueryProvider';

const BASE_QUERY_KEY = 'callSuggestions';

export function useGetCallSuggestionsQuery({
	vertical,
	persona,
	leadStage,
	enabled = true,
}: {
	vertical?: string;
	persona?: Api.ScorecardPersona;
	leadStage?: string;
	enabled?: boolean;
}) {
	const userSession = useUserSession();
	return useQuery({
		enabled,
		queryFn: async () => {
			const result = await userSession.webServiceHelper.callAsync<Api.ICallSuggestions>(
				Api.ImpersonationBroker.composeApiUrl({
					queryParams: { vertical, persona, leadStage },
					urlPath: `callSuggestions/`,
				}),
				'GET'
			);
			return result;
		},
		queryKey: [BASE_QUERY_KEY, vertical, persona, leadStage],
		refetchOnWindowFocus: false,
	});
}

export function invalidateGetCallSuggestions({
	vertical,
	persona,
	leadStage,
}: { vertical?: string; persona?: Api.ScorecardPersona; leadStage?: string }) {
	return reactQueryClient.invalidateQueries([BASE_QUERY_KEY, vertical, persona, leadStage]);
}
