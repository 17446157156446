import { StyleSheet } from 'aphrodite';
import { background } from '../../../../styles/colors';

export const styleSheet = StyleSheet.create({
	container: {
		display: 'flex',
		gap: 24,
		padding: 16,
		width: '100%',
	},
	finalPhrase: {},
	testerContainer: {
		display: 'flex',
		flexDirection: 'column',
		gap: 16,
		background,
		borderRadius: 8,
		width: '100%',
		padding: 16,
	},
	generateButton: {
		display: 'flex',
		flexWrap: 'wrap',
		gap: 8,
	},
	liveScorecard: {
		width: '100%',
	},
});
