import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../../models/hooks/appStateHooks';

export function useUpdatePhoneCallActiveScorecardMutation({
	onError,
}: {
	onError?: (err: Api.IOperationResultNoValue) => void;
}) {
	const userSession = useUserSession();
	return useMutation({
		onError,
		mutationFn: async ({ phoneCallId, activeScorecardId }: { phoneCallId: string; activeScorecardId: string }) => {
			const response = await userSession.webServiceHelper.callWebServiceAsync(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: `phonecall/${phoneCallId}/activeScorecardId?value=${activeScorecardId}`,
				}),
				'PUT',
				{}
			);
			return response.value;
		},
	});
}
