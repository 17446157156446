import * as Api from '@ViewModels';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../web/ReactQueryProvider';

const INFINITE_SATISFACTION_SURVEY_RESPONSES_QUERY_KEY = 'satisfactionSurveyResponsesFilter';

export function useSatisfactionSurveyResponsesFilter({
	enabled = true,
	impersonationContext,
	pageSize = 25,
	refetchOnWindowFocus = false,
	filterRequest,
	options = {},
}: {
	enabled?: boolean;
	filterRequest?: Api.ISatisfactionSurveyResponseFilterRequest;
	impersonationContext?: Api.IImpersonationContext;
	pageSize?: number;
	refetchOnWindowFocus?: boolean;
	options: Record<string, string>;
}) {
	const userSession = useUserSession();
	// @ts-ignore
	return useInfiniteQuery<Api.IPagedCollection<Api.ISatisfactionSurveyResponse>>({
		enabled,
		getNextPageParam: lastPage => lastPage.pageToken,
		queryFn: ({ pageParam }) => {
			return userSession.webServiceHelper.callAsync<Api.IPagedCollection<Api.ISatisfactionSurveyResponse>>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					queryParams: { pageSize, pageToken: pageParam, ...options },
					urlPath: `survey/satisfaction/response/filter`,
				}),
				'POST',
				filterRequest
			);
		},
		queryKey: [INFINITE_SATISFACTION_SURVEY_RESPONSES_QUERY_KEY, filterRequest?.surveyId, filterRequest, pageSize],
		refetchOnWindowFocus,
	});
}

export const invalidateSatisfactionSurveyResponseseFilter = (surveyId?: string) => {
	reactQueryClient.invalidateQueries([INFINITE_SATISFACTION_SURVEY_RESPONSES_QUERY_KEY, surveyId]);
};
