import * as React from 'react';
import * as Api from '@ViewModels';
import {
	useGetScorecardsByPhoneCallIdQuery,
	useUpdatePhoneCallActiveScorecardMutation,
	invalidateGetScorecardByPhoneCallId,
} from '../../../queries';
import { useTelephony } from '../../../contexts/telephony';
import { useTwilio, IKeywordSuggestion, TwilioContextProvider } from '../context';
import { css } from 'aphrodite';
import { styleSheet } from './styles';
import { LiveScorecard } from '../../../components/ai/LiveScorecard';
import { Badge } from '../../../../web/components/dataBoards/Badge';
import { AidaDraggable } from '../../AidaDraggable';
import { DraggableData, DraggableEvent } from 'react-draggable';
import { DraggableIcon } from '../../svgs/icons/audio/DraggableIcon';
import { brandPrimary } from '../../../styles/colors';

const MrrySuggestions = () => {
	const { suggestions, setSuggestions } = useTwilio();

	const dismiss = (suggestion: IKeywordSuggestion) => {
		const newSuggestions = [...suggestions];
		const currentIndex = newSuggestions.findIndex(newSuggestion => suggestion.title === newSuggestion.title);
		newSuggestions.splice(currentIndex, 1);
		setSuggestions(newSuggestions);
	};

	if (suggestions.length === 0) {
		return null;
	}

	return (
		<div className={css(styleSheet.suggestionsContainer)}>
			{suggestions.map((suggestion: IKeywordSuggestion, index: number) => {
				return (
					<button key={`${suggestion.title}-${index}`} onClick={() => dismiss(suggestion)}>
						<h5 className={css(styleSheet.header)}>
							<span>Suggestion</span>
							<Badge text={suggestion.title} foregroundColor='#DBF2C3' />
						</h5>
						<p className={css(styleSheet.message)}>{suggestion.message}</p>
					</button>
				);
			})}
		</div>
	);
};

export const ScorecardWrapper = ({ phoneCall }: { phoneCall: Api.IPhoneCall }) => {
	const [activeScorecard, setActiveScorecard] = React.useState<Api.IScorecard>(phoneCall.activeScorecard);
	const [scorecards, setScorecards] = React.useState<Api.IScorecard[]>([]);
	const getScorecardsByPhoneCallIdQuery = useGetScorecardsByPhoneCallIdQuery({ phoneCallId: phoneCall.id });
	const updatePhoneCallActiveScorecard = useUpdatePhoneCallActiveScorecardMutation({});

	React.useEffect(() => {
		if (!getScorecardsByPhoneCallIdQuery.data?.length) {
			return;
		}
		const fetchedScoreboards = getScorecardsByPhoneCallIdQuery.data;
		setScorecards(fetchedScoreboards);
		if (phoneCall.activeScorecardId) {
			const match = fetchedScoreboards.find(x => x.id === phoneCall.activeScorecardId);
			setActiveScorecard(match);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getScorecardsByPhoneCallIdQuery.data]);

	if (!scorecards?.length) {
		return null;
	}

	const gkScorecard = scorecards.find(x => x.persona === Api.ScorecardPersona.GateKeeper);
	const dmScorecard = scorecards.find(x => x.persona === Api.ScorecardPersona.DecisionMaker);

	const toggleActiveScorecard = async (newValue: Api.IScorecard) => {
		await updatePhoneCallActiveScorecard.mutateAsync({ phoneCallId: phoneCall.id, activeScorecardId: newValue.id });
		invalidateGetScorecardByPhoneCallId(phoneCall.id);
		setActiveScorecard(newValue);
	};

	const toggle = (
		<div className={css(styleSheet.buttonToggleGroup)}>
			{gkScorecard ? (
				<button
					className={css(
						styleSheet.buttonToggle,
						activeScorecard?.id === gkScorecard?.id ? styleSheet.activeButtonToggle : null
					)}
					onClick={() => toggleActiveScorecard(gkScorecard)}
				>
					GK
				</button>
			) : null}
			{dmScorecard ? (
				<button
					className={css(
						styleSheet.buttonToggle,
						activeScorecard?.id === dmScorecard?.id ? styleSheet.activeButtonToggle : null
					)}
					onClick={() => toggleActiveScorecard(dmScorecard)}
				>
					DM
				</button>
			) : null}

			<DraggableIcon fill={brandPrimary} className='sales-ai-drag-handle' />
		</div>
	);

	return (
		<div className={css(styleSheet.scorecardContainer)}>
			{activeScorecard ? <LiveScorecard scorecard={activeScorecard} toggle={toggle} /> : null}

			{!activeScorecard ? (
				<div className={css(styleSheet.scorecardSelector)}>
					<h5>Choose a Score Card</h5>

					<p className={css(styleSheet.paragraph)}>
						The live score card will help you evaluate if your conversation is on the right track to close a deal.
					</p>

					<div className={css(styleSheet.pillButtonGroup)}>
						{gkScorecard ? (
							<button
								className={css(styleSheet.pillButton, styleSheet.redPill)}
								onClick={() => toggleActiveScorecard(gkScorecard)}
							>
								Gate Keeper
							</button>
						) : null}

						{dmScorecard ? (
							<button
								className={css(styleSheet.pillButton, styleSheet.greenPill)}
								onClick={() => toggleActiveScorecard(dmScorecard)}
							>
								Decision Maker
							</button>
						) : null}
					</div>
				</div>
			) : null}
		</div>
	);
};

export const SalesAi = () => {
	const { phoneCall } = useTelephony();
	const [dragging, setDragging] = React.useState<{
		active?: boolean;
		x?: number;
		y?: number;
	}>({ active: false, x: 0, y: 0 });

	if (!phoneCall) return null;

	const onDrag = (event: DraggableEvent, { x, y }: DraggableData) => {
		event.stopPropagation();
		setDragging({ active: true, x, y });
	};

	const onDragStop = (_: DraggableEvent, { x, y }: DraggableData) => {
		const { active } = dragging;
		if (active) {
			setDragging({ active: false, x, y });
		}
	};

	return (
		<TwilioContextProvider>
			<AidaDraggable
				onDrag={onDrag}
				onStop={onDragStop}
				defaultPosition={{ x: dragging.x, y: dragging.y }}
				position={{ x: dragging.x, y: dragging.y }}
				handle='.sales-ai-drag-handle'
				isDraggable={true}
			>
				<div className={`${css(styleSheet.floatingPanel)} sales-ai-drag-container`}>
					<ScorecardWrapper phoneCall={phoneCall} />
					<MrrySuggestions />
				</div>
			</AidaDraggable>
		</TwilioContextProvider>
	);
};
