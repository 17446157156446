import { css } from 'aphrodite';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { ICompany } from '../../../../extViewmodels';
import { getDisplayName } from '../../../../models/UiUtils';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import { CallRecordingDetails } from '../../../components/CallRecordingDetails';
import { LeadCard2 } from '../../../components/LeadCard2';
import { LeadViewWrapper } from '../../../components/leadView/LeadViewWrapper';
import { CategoryList } from '../../../components/leadView/components/CategoryList';
import { OutcomeInfo } from '../../../components/leadView/components/OutcomeInfo';
import { RecordingIndicator } from '../../../components/leadView/components/RecordingIndicator';
import { TextColumn } from '../../../components/leadView/components/TextColumn';
import { PlayIcon } from '../../../components/svgs/icons/audio/PlayIcon';
import { ILeadViewFilter, useLeadView } from '../../../entities/LeadView/useLeadView';
import { ViewportContext } from '../../../hooks/useViewport';
import { white } from '../../../styles/colors';
import { ILeadServedOutcomeView } from '../../../viewModels/leads/interfaces';
import { LeadServedSource } from '../../../viewModels/queue';
import { Filters } from '../Filters';
import { styleSheet } from '../styles';
import { ExportReport } from './ExportReport';

const getInitialDateRange = () => {
	const end = new Date();
	const start = new Date();
	start.setDate(end.getDate() - 7);
	return {
		end,
		start,
	};
};

interface IRouteMatch {
	recentCallId: string;
}

export const CallLogsTab: React.FC = () => {
	const { data, empty, get, status } = useLeadView();

	const userSession = useUserSession();

	const [recentCallId, setRecentCallId] = useState(null);
	const [autoplay, setAutoplay] = useState(false);

	const history = useHistory();

	const matchRecentCallId = useRouteMatch<IRouteMatch>('/call-log/:recentCallId');

	const recentCallIdParam = matchRecentCallId?.params?.recentCallId;

	const filterByCategoryName = history?.location?.search?.replace('?category=', '');

	const [criteria, setCriteria] = useState<ILeadViewFilter>(
		filterByCategoryName
			? {
					category: filterByCategoryName,
				}
			: {
					date: getInitialDateRange(),
					user: userSession.user,
				}
	);

	useEffect(() => {
		setRecentCallId(recentCallIdParam);
	}, [recentCallIdParam]);

	const { isBelowSmall } = useContext(ViewportContext);

	const handleGet = (shouldNotTriggerLoading?: boolean) => get({ filter: criteria, shouldNotTriggerLoading });

	const handleFilterChange = (output: ILeadViewFilter) => {
		if (filterByCategoryName) {
			history.push(`/call-log`);
		}
		get({ filter: output, shouldReset: true });
		setCriteria(output);
	};

	const handleLogDetails = (callId: string, isAutoplay?: boolean) => () => {
		history.push(`/call-log/${callId || ''}`);
		setAutoplay(isAutoplay);
	};

	const showExport = !empty;

	const renderFilter = (
		<Filters filter={criteria} onChange={handleFilterChange}>
			{showExport ? <ExportReport filter={criteria} /> : null}
		</Filters>
	);

	const handleRowClick = (item: ILeadServedOutcomeView) => {
		return handleLogDetails(item?.phoneCall?.id);
	};

	const handleClickPlay = (phoneCallId: string) => () => {
		handleLogDetails(phoneCallId, true)();
	};

	const cardViewComponent = (item: ILeadServedOutcomeView) => (
		<LeadCard2 source={LeadServedSource.AppointmentConfirmation} lead={item} />
	);

	const getLeadUrl = (company: ICompany) => {
		const { origin, pathname } = location;
		return `${origin + pathname}#/queue?from=${LeadServedSource.CallLog}&companyId=${company?.id}`;
	};

	const handleOpenLead = (company: ICompany) => () => {
		const companyId = company?.id;
		history.push(`/queue?from=${LeadServedSource.CallLog}&companyId=${companyId}`);
	};

	const handleCloseDetails = () => {
		get({ filter: criteria, shouldNotTriggerLoading: true });
		handleLogDetails(null)();
	};

	return (
		<>
			<LeadViewWrapper<ILeadServedOutcomeView>
				cardViewComponent={cardViewComponent}
				columns={[
					{
						Component: ({ company }) => {
							const companyName = company?.companyName || 'Unknown';
							const phoneNumber = company?.phoneNumbers[0]?.metadata?.standard || 'Unknown';
							return (
								<TextColumn
									hrefLink={getLeadUrl(company)}
									isSmall={isBelowSmall}
									onClick={!isBelowSmall ? handleOpenLead(company) : null}
									subtext={phoneNumber}
									text={companyName}
								/>
							);
						},
						label: 'Company',
						width: isBelowSmall ? 220 : 240,
					},
					{
						Component: ({ creator }) => <TextColumn text={getDisplayName(creator)} isSmall={true} />,
						label: 'SDR',
						width: isBelowSmall ? 120 : 200,
					},
					{
						Component: ({ interaction }) => (
							<OutcomeInfo
								name={interaction?.outcome?.name}
								color={interaction?.outcome?.hexColor}
								date={interaction?.date}
							/>
						),
						hide: isBelowSmall,
						label: 'Time & Outcome',
						width: 200,
					},
					{
						Component: ({ phoneCall }) => {
							if (phoneCall?.recording?.publicUrl) {
								return <PlayIcon fillColor={white} className={css(styleSheet.playIcon)} />;
							}
							return null;
						},
						hide: !isBelowSmall,
						label: '',
						width: 20,
					},
					{
						Component: ({ phoneCall }) => {
							return (
								<RecordingIndicator
									creationDate={phoneCall?.creationDate}
									creatorName={getDisplayName(phoneCall?.creator)}
									id={phoneCall?.id}
									onClickPlay={handleClickPlay(phoneCall?.id)}
									phoneNumber={phoneCall?.phoneNumber?.standard}
									publicUrl={phoneCall?.recording?.publicUrl}
									recordingChannels={phoneCall?.recordingChannels}
									transcriptionId={phoneCall?.transcriptionId}
								/>
							);
						},
						hide: isBelowSmall,
						label: 'Recording',
						width: 120,
					},
					{
						Component: ({ phoneCall }) => <CategoryList categories={phoneCall?.categories} />,
						hide: isBelowSmall,
						label: 'Recording Category',
						width: 160,
					},
				]}
				data={data}
				filterComponent={renderFilter}
				getRowToClick={handleRowClick}
				hideHeader={true}
				isEmpty={empty}
				isLoading={status === 'working' || status === 'waiting'}
				onLoadMore={handleGet}
				title='Call Logs'
			/>
			{recentCallId && (
				<CallRecordingDetails autoplay={autoplay} onClickToClose={handleCloseDetails} recentCallId={recentCallId} />
			)}
		</>
	);
};
