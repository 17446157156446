import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../../models/hooks/appStateHooks';

export function useEvaluatePhraseMutation({
	scorecardId,
}: {
	scorecardId: string;
}) {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: async ({
			phrase,
			callTrack,
		}: {
			phrase: string;
			callTrack: Api.CallTrack;
		}) => {
			const response = await userSession.webServiceHelper.callWebServiceAsync<Api.IScorecard>(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: `scorecard/${scorecardId}/evaluatePhrase`,
				}),
				'POST',
				{ phrase, callTrack }
			);
			return response.value;
		},
	});
}
