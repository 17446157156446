/* eslint-disable react-hooks/exhaustive-deps */
import { IPhoneCall } from '../../../extViewmodels';
import { getDisplayName } from '../../../models/UiUtils';
import { LoadingSpinner } from '../../../web/components/LoadingSpinner';
import { usePhoneCall } from '../../entities/PhoneCall/usePhoneCall';
import { getBoolFromStatus } from '../../entities/utils/getBoolFromStatus';
import { ViewportContext } from '../../hooks/useViewport';
import { Group } from '../leadView/components/Group';
import { CallDetailsAudioPlayer } from './components/CallDetailsAudioPlayer';
import { CallDetailsCard } from './components/CallDetailsCard';
import { CallDetailsCategory } from './components/CallDetailsCategory';
import { CallDetailsHeader } from './components/CallDetailsHeader';
import { CallDetailsNotes } from './components/CallDetailsNotes';
import { CallDetailsTitle } from './components/CallDetailsTitle';
import { CallDetailsTranscription } from './components/CallDetailsTranscription';
import { styleSheet } from './styles';
import { animated, config, useSpring } from '@react-spring/web';
import { css } from 'aphrodite';
import { useContext, useEffect, useState } from 'react';
import * as React from 'react';
import { CallDetailsScorecard } from './components/CallDetailsScorecard';

interface IProps {
	autoplay: boolean;
	onClickToClose: () => void;
	recentCallId: string;
}

export const CallRecordingDetails: React.FC<IProps> = ({ autoplay = false, onClickToClose, recentCallId }) => {
	const sideDrawer = {
		from: { right: -500 },
		to: { right: 0 },
	};

	const bottomDrawer = {
		from: { top: window.innerHeight },
		to: { top: 10 },
	};

	const [animationDone, setAnimationState] = useState(false);
	const { isBelowSmall } = useContext(ViewportContext);

	const direction = isBelowSmall ? bottomDrawer : sideDrawer;

	const spring = useSpring({
		config: config.default,
		onRest: () => {
			setAnimationState(true);
		},
		...direction,
	});

	const { data, empty, getById, status } = usePhoneCall<IPhoneCall>();
	const { isLoading, isEmpty } = getBoolFromStatus({ empty, status });

	useEffect(() => {
		if (animationDone) {
			getById(recentCallId);
		}
	}, [animationDone, recentCallId]);

	const renderEmpty = <div className={css(styleSheet.emptyContent)}>Cannot find Phone Call information.</div>;

	const hasRecording = data?.recording?.publicUrl;

	const haveEnoughDuration = data?.duration >= 3;

	const hasScorecard = data?.activeScorecardId;

	const renderContent = (
		<>
			<CallDetailsTitle isMobile={isBelowSmall} companyId={data?.companyId} />
			<Group vertical={true} spacingSize={20} className={css(styleSheet.sectionWrapper)}>
				<CallDetailsCard
					callDate={data?.creationDate}
					callStatus={data?.callStatus}
					sdrName={getDisplayName(data?.creator)}
					sdrPhoneNumber={data?.creatorPhoneNumber?.standard}
				/>
				{hasRecording && (
					<CallDetailsAudioPlayer
						autoplay={autoplay}
						contactId={data?.contactIds?.[0]}
						creationDate={data?.creationDate}
						creatorName={getDisplayName(data?.creator)}
						phoneCallId={data?.id}
						phoneNumber={data?.phoneNumber?.standard}
						recordingSource={data?.recording?.publicUrl}
					/>
				)}
				<CallDetailsCategory phoneCallCategories={data?.categories} phoneCallId={data?.id} />
				{hasRecording && haveEnoughDuration && (
					<CallDetailsTranscription transcriptionId={data?.transcriptionId} phoneCall={data} />
				)}
				{hasScorecard ? <CallDetailsScorecard phoneCall={data} /> : null}
				<CallDetailsNotes phoneCallId={recentCallId} />
			</Group>
		</>
	);

	return (
		<animated.div className={css(styleSheet.container, isBelowSmall && styleSheet.containerMobile)} style={spring}>
			<CallDetailsHeader isMobile={isBelowSmall} onClickToClose={onClickToClose} />
			<div className={css(styleSheet.content, isBelowSmall && styleSheet.contentMobile)}>
				{isLoading ? <LoadingSpinner type='login' /> : isEmpty ? renderEmpty : renderContent}
			</div>
		</animated.div>
	);
};
