import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../../web/ReactQueryProvider';

const BASE_QUERY_KEY = 'scorecardTemplates';

export function useGetScorecardTemplatesQuery({
	enabled = true,
}: {
	enabled?: boolean;
}) {
	const userSession = useUserSession();
	return useQuery({
		enabled,
		queryFn: async () => {
			const result = await userSession.webServiceHelper.callAsync<Api.IScorecardTemplate[]>('scorecardTemplate', 'GET');
			return result;
		},
		queryKey: [BASE_QUERY_KEY],
		refetchOnWindowFocus: false,
	});
}

export function invalidateGetScorecardTemplates() {
	return reactQueryClient.invalidateQueries([BASE_QUERY_KEY]);
}
