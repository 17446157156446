import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export const useUpdateEventRegistrationSurveyCustomFormMutation = ({
	impersonationContext,
	onError,
	onSuccess,
}: {
	impersonationContext?: Api.IImpersonationContext;
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: (data: Api.IEventRegistrationSurvey) => void;
}) => {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: ({ request, id }: { request: Api.EventRegistrationSurveyCustomFormRequest; id: string }) => {
			return userSession.webServiceHelper.callAsync<Api.IEventRegistrationSurvey>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: `survey/eventregistration/${id}/customForm`,
				}),
				'PUT',
				request.customForm
			);
		},
		onError,
		onSuccess,
	});
};
